import React from 'react'

const TestimonialsSection = () => {
    return (
        <>
            <section className="testimonial  inner-page py-5 ">
                <div className="container">

                    <div className="row tstimonialSec">

                        <div className="col-lg-4 col-md-6 inner-textBx">
                            <div className="textBx"> <img src="/images/tstimnl-img1.jpg" alt=""/>
                                <h3>John <span>Doe </span> </h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                                    dolore.</p>

                                <blockquote> April 27, 2022 |</blockquote>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 inner-textBx">
                            <div className="textBx"> <img src="/images/tstimnl-img2.jpg" alt=""/>
                                <h3>Kelly</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                                    dolore
                                    .</p>

                                <blockquote> April 27, 2022 |</blockquote>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 inner-textBx">
                            <div className="textBx"> <img src="/images/tstimnl-img1.jpg" alt=""/>
                                <h3>John <span>Doe </span> </h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                                    dolore.</p>

                                <blockquote> April 27, 2022 |</blockquote>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 inner-textBx">
                            <div className="textBx"> <img src="/images/tstimnl-img2.jpg" alt=""/>
                                <h3> Jen </h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                                    dolore.</p>

                                <blockquote> April 27, 2022 |</blockquote>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 inner-textBx">
                            <div className="textBx"> <img src="/images/tstimnl-img1.jpg" alt=""/>
                                <h3>John <span>Doe </span> </h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                                    dolore.</p>

                                <blockquote> April 27, 2022 |</blockquote>

                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default TestimonialsSection