import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { MyTrialEnd } from "../context/trialend";

function TrielProtection({ children }) {
    const {isTrial } =useContext(MyTrialEnd)
    if (isTrial) {
        return <Navigate to="/pricing" replace />;
    }
  
    return children;
}
export default TrielProtection;
