import { createContext, useState } from "react";



const MyAdsCheck=createContext()


const CustomAdsProtection=({children})=>{
    const [roleName,setRoleName]= useState({id:"",role:""})
    return <MyAdsCheck.Provider value={{roleName,setRoleName}}>{children}</MyAdsCheck.Provider>
}


export default CustomAdsProtection
export {MyAdsCheck}