import Stream from "./Stream";
import ChatNdParticipants from "./ChatNdParticipants";
// import Controls from "./Controls";

function Room({ liveStreams }) {
  return (
    <>
      <div className="livesessionroom">
        <div className='room__streamSpace'>
          <Stream liveStreams={liveStreams} />
          {/* <Controls /> */}
        </div>
        <ChatNdParticipants />
      </div>
    </>
  );
}

export default Room;
