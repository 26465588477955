import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { MyAdsCheck } from "../context/adsProtection";

function AdsProtection({ children }) {

    const adsData =useContext(MyAdsCheck)
    if (adsData?.roleName?.role=='adsUser') {
        console.log(adsData,"p")
        return <Navigate to={`/`} replace />;
    }
    return children;
}
export default AdsProtection;
