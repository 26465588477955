import { useState } from "react";
import { selectPeers } from "@100mslive/react-sdk";
import {
  selectHMSMessages,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import { Close } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import { IconButton, Button, Switch } from "@mui/material";
import Message from "./Message";

function ChatNdParticipants({ chat, setChat }) {
  const [selectedOption, setSelectedOption] = useState("chat");
  const [message, setMessage] = useState("");
  const messages = useHMSStore(selectHMSMessages);
  const hmsActions = useHMSActions();
  const peers = useHMSStore(selectPeers);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(message);
    hmsActions.sendBroadcastMessage(message);
    setMessage("");
  };

  // console.log(chat);

  return (
    <div className={chat ? "rightBox" : "rightBox hide"}>
      <div className="rightBox__head">
        <span
          onClick={() => setSelectedOption("chat")}
          className={selectedOption === "chat" ? "selected" : ""}
        >
          Chat
        </span>
        <span
          className={selectedOption === "participants" ? "selected" : ""}
          onClick={() => setSelectedOption("participants")}
        >
          Participants
        </span>
        <IconButton onClick={() => setChat(false)}>
          <Close />
        </IconButton>
      </div>
      <div className="rightBox__optionView">
        {selectedOption === "chat" && (
          <>
            <div className="rightBox__chats">
              {/* Messages */}
              {messages.map((msg) => (
                <Message key={msg.id} message={msg} />
              ))}
            </div>
            <form name="send-messge" onSubmit={handleSubmit}>
              <input
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                placeholder="Write your message"
              />
            </form>
          </>
        )}
        {selectedOption === "participants" && (
          <div className="rightBox__participants">
            {peers.map((peer) => (
              <div className="rightBox__participant">
                {peer.name}
                {/* <p>{peer.roleName}</p> */}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default ChatNdParticipants;
