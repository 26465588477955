

import config from "../../../../config";

function s3ToObjectUrl(path) {
    // s3://turntospeak/...
    // https://s3.amazonaws.com/turntospeak/...
    let res = path.replace("s3://", "https://s3.amazonaws.com/");
    console.log(res);
    return res;
}

const Video = ({ src, debate }) => {
    const poster = debate?.imageUrl ? config.apiUrl +'/'+ debate.imageUrl : "";
    return (
        <video width="100%" poster={poster}>
            {/* <source src={s3ToObjectUrl(src)} type="video/mp4" /> */}
            Sorry, your browser doesn't support embedded videos.
        </video>
    );
};

export default Video;
