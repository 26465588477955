import { Link } from '@mui/material'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import axios from 'axios'
import React, { useState, useEffect, useCallback, useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Auth } from '../../services/Auth'
import config from '../../config'
import { MyTrialEnd } from '../../context/trialend'
import { MyAdsCheck } from '../../context/adsProtection'

const Login = () => {
  const navigate = useNavigate()

  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [message, setMessage] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const trielcheck = useContext(MyTrialEnd)
  const [open, setOpen] = React.useState(false)
  const adsdata = useContext(MyAdsCheck)

  const handleClickOpen = () => {
    setOpen(true)
    setEmail('')
    setNewPassword('')
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmitPass = useCallback(async () => {
    try {
      const { status, data } = await axios.post(
        `${config.apiUrl}/api/user/forgotPassword`,
        {
          email,
          password: newPassword,
        },
      )
      console.log(status, data)
      if (status === 200) {
        setOpen(false)
        if (data?.data) {
          window.alert(data?.data)
        }
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        window.alert(error?.response?.data?.message)
      }
    }
  }, [email, newPassword])

  const logInSubmit = (e) => {
    // console.log('hi')
    e.preventDefault()
    Auth.login({ username: name, password: password }).then(
      (data) => {
        // console.log('response data', data)
        if (data.code === 200) {
          // NotificationManager.success(data.message, 'success!', 2000);
          //   localStorage.setItem("authenticated", true);
          localStorage.setItem(
            'turn2speak_auth_user',
            JSON.stringify(data.data.user),
          )
          localStorage.setItem('turn2speak_auth_token', data.data.accessToken)
          // localStorage.setItem('refreshtoken', data.refresh_token)
          setMessage(data.message)
          //   navigate("/");
          if(!data.data.user.role.name=='admin'){

            if (data.data.user.flag == true || data.data.user.flag == 'true') {
              trielcheck.setTrialEnd(true)
              window.location.assign('/pricing')
              return
            }
          }
          if (data.data.user.role.name == 'adsUser') {
            console.log('adsdata', adsdata)
            adsdata.setRoleName({ id: data.data.user._id, role: 'adsUser' })
            window.location.assign('/')
            return
          }

          window.location.assign('/')
        }
        console.log(data)
        setErrMsg(data.message)
      },
      (error) => {
        console.log(error)
        setErrMsg(error.toString())
      },
    )
  }

  return (
    <>
      <section>
        <div className="container">
          <div className="row mt-4">
            <div className="col-md-12">
              <div className="row justify-content-center">
                <div className=" col-lg-12 col-md-12 rgForm">
                  <div className="card o-hidden border-0 shadow-lg my-5">
                    <div className="card-body p-0">
                      <div className="row">
                        <div className="col-lg-4 d-none d-lg-block bg-login-image"></div>
                        <div className="col-lg-8">
                          <div className="p-md-5 p-3">
                            <div className="text-center mb-4">
                              {' '}
                              <img src="images/logo.png" alt="" />
                            </div>
                            <hr />

                            <form
                              className="user row mt-4"
                              onSubmit={logInSubmit}
                              autoComplete="off"
                            >
                              <div className="col-md-12 mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="name"
                                  aria-describedby=""
                                  autoComplete="off"
                                  placeholder="Email or Username"
                                  value={name}
                                  onChange={(e) => {
                                    setName(e.target.value)
                                    setErrMsg('')
                                  }}
                                  required
                                />
                              </div>
                              <div className="col-md-12 mb-3">
                                <input
                                  type="password"
                                  className="form-control"
                                  id="password"
                                  aria-describedby=""
                                  placeholder="Password"
                                  value={password}
                                  autoComplete="off"
                                  onChange={(e) => {
                                    setPassword(e.target.value)
                                    setErrMsg('')
                                  }}
                                  required
                                />
                              </div>

                              <div className="col-md-12">
                                <div onClick={handleClickOpen}>
                                  <Link href="#" underline="hover">
                                    Forget password
                                  </Link>
                                </div>
                                {/* <div className="mb-3 form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="exampleCheck1"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleCheck1"
                                  >
                                    Keep me signed in
                                  </label>
                                </div> */}
                                <div className="col-md-12">
                                  <hr />
                                </div>
                                <p style={{ color: 'red' }}>{errMsg}</p>
                                <div className="col-md-12 btnScn">
                                  <button type="submit" className="btn btn1">
                                    Login
                                  </button>
                                  <NavLink
                                    to="/signup"
                                    className="btn btn1 mx-4"
                                  >
                                    Sign Up{' '}
                                  </NavLink>
                                </div>
                              </div>
                            </form>

                            <hr />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Forget password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            // id="name"
            autoComplete="off"
            label="Email or Username"
            type="email"
            fullWidth
            variant="standard"
            defaultValue={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="dense"
            autoComplete="off"
            label="New password"
            type="password"
            fullWidth
            variant="standard"
            defaultValue={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmitPass}>Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Login
