import React, { memo, useCallback, useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import config from '../../../config'
import axios from 'axios'

function PricingTableSection() {
  const queryClient = useQueryClient()
  const data = queryClient.getQueryData('subscription')

  const subscription = useCallback(async () => {
    await queryClient.invalidateQueries('subscription')
  }, [queryClient])
  const navigate = useNavigate()

  const handleSkip = () => {
    localStorage.removeItem('turn2speak_auth_token')
    localStorage.removeItem('turn2speak_auth_user')
    navigate('/login')
  }

  useEffect(() => {
    subscription()
  }, [])

  console.log(data)
  let myuserData
  // if(myuserData==undefined || myuserData=='undefined'){
  //   return window.location.assign("/")
  // }
  myuserData = JSON.parse(localStorage?.getItem('user'))

  const freeTrial = async (e) => {
    e.preventDefault()
    console.log('Myuserdata', myuserData)
    axios
      .post(`${config.apiUrl}/api/payment/subscription`, {
        id: myuserData._id,
        plan: 0,
        noofdays: 14,
      })
      .then((res) => {
        console.log(res.data.data.token)
        alert('Trial Pack Activated To Your Account')
        localStorage.setItem('turn2speak_auth_user', JSON.stringify(myuserData))
        localStorage.setItem('turn2speak_auth_token', res.data.data.token)
        window.location.assign('/')
      })
      .catch((err) => {
        console.log('Error', err.message)
      })
  }

  return (
    <>
      <section>
        <div className="pricingTable">
          <div className="text-end pe-5">
            <button className="btn btn-danger btn2" onClick={handleSkip}>
              Skip
            </button>
          </div>
          <h2 className="pricingTable-title">
            Find a plan that's right for you.
          </h2>
          {data ? (
            <ul className="pricingTable-firstTable mt-5">
              {data.map(function (row, i) {
                return (
                  <li key={i} className="pricingTable-firstTable_table">
                    <h1 className="pricingTable-firstTable_table__header">
                      {row.planName}
                    </h1>
                    <p className="pricingTable-firstTable_table__pricing">
                      <span>$</span>
                      <span>{row.price}</span>
                      <br />
                      {row?.duration == 14 ? (
                        <span>{row?.duration}-Days</span>
                      ) : (
                        <span>
                          {row.duration}-{row.duration > 1 ? 'Months' : 'Month'}
                        </span>
                      )}
                    </p>
                    {row?.featureIds ? (
                      <ul className="pricingTable-firstTable_table__options">
                        {row.featureIds.map(function (row2, i2) {
                          return <li key={i2}>{row2?.name}</li>
                        })}
                      </ul>
                    ) : null}
                    {row?.price == 0 ? (
                      <NavLink
                        onClick={freeTrial}
                        className="pricingTable-firstTable_table__getstart"
                        style={{
                          pointerEvents: `${
                            myuserData?.flag ? 'none' : 'auto'
                          }`,
                        }}
                      >
                        Get Started Now
                      </NavLink>
                    ) : (
                      <NavLink
                        to={`/Subscriptionpayment/${row.price}/${row.duration}`}
                        className="pricingTable-firstTable_table__getstart"
                      >
                        Get Started Now
                      </NavLink>
                    )}
                  </li>
                )
              })}
            </ul>
          ) : null}

          <h3 className="pricingTable-subtitle mt-5">
            <NavLink to="/signup" className="btn btn2 mx-2">
              Sign up
            </NavLink>{' '}
            with a 14-day free trial.
          </h3>
        </div>
      </section>
    </>
  )
}

export default PricingTableSection
