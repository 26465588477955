import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import config from "../../config";
import { AuthState } from "../../context/auth";
import Room from "../Room/Room";
import WaitingRoom from "../Room/WaitingRoom";
import { selectHLSState } from "@100mslive/react-sdk";
import { useHMSActions, selectLocalPeer } from "@100mslive/react-sdk";
import { useHMSStore, selectIsConnectedToRoom } from "@100mslive/react-sdk";
// import { MyAdsContext } from "../../context/adscontext";

function SingleDebate() {
  console.log("SingleDebate");
  const hmsActions = useHMSActions();
  const localPeer = useHMSStore(selectLocalPeer);
  const hlsState = useHMSStore(selectHLSState);
  const isConnected = useHMSStore(selectIsConnectedToRoom);

  const { debateId } = useParams();
  const { myProfile, isSignedIn } = AuthState();
  const [activeRoom, setActiveRoom] = useState(false);

  const { data } = useQuery(
    ["singleDebate", debateId],
    async () => {
      const apiurl = `${config.apiUrl}/api/user/debates/${debateId}`;
      const { data } = await axios.get(apiurl);
      return data;
    },
    {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchInterval: 5000,
    }
  );
  const debate = data && data?.debate;
  const isStarted = useMemo(() => {
    return debate && debate.isStarted && isSignedIn ? true : false;
  }, [debate, isSignedIn]);
  const isAdmin = useMemo(() => {
    if (isSignedIn && myProfile && myProfile?.role?.name === "admin") {
      return true;
    } else {
      return false;
    }
  }, [myProfile, isSignedIn]);

  // issue: fixed-1749-admin-stop-debate-then-respective-call
  const leaveRoom = async () => {
    console.log("activeRoom", activeRoom, "isConnected", isConnected, "isStarted", isStarted, "hlsState", hlsState);
    if (isConnected) {
      // await hmsActions.setLocalAudioEnabled(false);
      // await hmsActions.setLocalVideoEnabled(false);
      if (isAdmin) {
        if (localPeer && localPeer.roleName === "broadcaster") {
          if (hlsState && hlsState.running) {
            await hmsActions.stopHLSStreaming();
          }
        }
      }
      await hmsActions.leave();
      setActiveRoom(false);
    }
  };

  // useEffect(() => {
  //   if (!isStarted) {
  //     leaveRoom();
  //     setActiveRoom(false);
  //   }
  // }, [isStarted]);

  useEffect(() => {
    if (isStarted && isConnected) {
      setActiveRoom(true);
    }
    else if (isStarted && !isConnected) {
      setActiveRoom(false);
    }
    else if (!isStarted && !isConnected) {
      setActiveRoom(false);
    }
    else if (!isStarted) {
      setActiveRoom(false);
      leaveRoom();
    }
  }, [isStarted, isConnected]);

  useEffect(() => {
    leaveRoom();
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", leaveRoom);
    window.addEventListener("onunload", leaveRoom);
    return () => {
      window.removeEventListener("onunload", leaveRoom);
      window.removeEventListener("beforeunload", leaveRoom);
    };
  }, []);

  console.log("activeRoom", activeRoom, "isConnected", isConnected, "isStarted", isStarted, "hlsState", hlsState);


  // console.log(data);

  ///Adding ads

  const [timerAttVertical, setTimerArrVertical] = useState([]);
  const [timerAttHorizontal, setTimerArrHorizontal] = useState([]);
  const [imgArrVertical, setImgArrVertical] = useState([]);
  const [srcVertical, setSrcVertical] = useState({ image: "", link: "" });
  const [toggleimgVertical, setToggleImgVertical] = useState(false);
  const [toogleaxios, settoggleaxios] = useState(true);

  const [imgArrHorizontal, setImgArrHorizontal] = useState([]);
  const [srcHorizontal, setSrcHorizontal] = useState({ image: "", link: "" });
  const [toggleimgHorizontal, setToggleImgHorizontal] = useState(false);
  const [toogleaxiosHorizontal, settoggleaxiosHorizontal] = useState(true);

  const { data: imageData, isLoading } = useQuery(
    ["ads"],
    async () => {
      const imgurl = `${config.apiUrl}/api/ads`;
      const { data } = await axios.get(imgurl);
      console.log("Data", data);
      return data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    }
  );

  useEffect(() => {
    console.log("isLoading", isLoading);
    if (!isLoading) {
      localStorage.setItem(
        "bufferTime",
        JSON.stringify(debate && debate?.bufferTime)
      );
      let vt = [];
      let vi = [];
      let ht = [];
      let hi = [];
      imageData.forEach((ele) => {
        let arrv = ele.adsImages.filter(
          (f) => f.imageOrientation === "vertical"
        );
        let arrh = ele.adsImages.filter(
          (f) => f.imageOrientation === "horizontal"
        );
        console.log(arrv, arrh);
        if (arrv.length) {
          // vt.push(3);
          if (parseInt(ele.duration)) {
            vt.push(parseInt(ele.duration));
          } else {
            vt.push(3);
          }
          vi.push(arrv);
        }
        if (arrh.length) {
          // ht.push(3);
          if (parseInt(ele.duration)) {
            ht.push(parseInt(ele.duration));
          } else {
            ht.push(3);
          }
          hi.push(arrh);
        }
      });
      setImgArrVertical(vi);
      setTimerArrVertical(vt);
      setImgArrHorizontal(hi);
      setTimerArrHorizontal(ht);
    }
  }, [isLoading, imageData, debate]);

  function displayimgVertical(usrIndex, imgIndex) {
    if (timerAttVertical.length <= usrIndex) {
      usrIndex = 0;
      imgIndex = 0;
      setToggleImgVertical(true);
      let stop = setTimeout(() => {
        console.log("bufferTime", debate.bufferTime);
        setToggleImgVertical(false);
        displayimgVertical(0, 0);
        //Reseting the ads value to its initial
        axios
          .post(`${config.apiUrl}/api/ads/showadslive`, {
            imageV: imgArrVertical[0][0].image,
            linkV: imgArrVertical[0][0].link,
            flagV: true,
            imageOrientation: "vertical",
          })
          .then((res) => {
            settoggleaxios(false);
            console.log("Done", res.data);
            // alert('Hello we are done')
          })
          .catch((err) => {
            console.log(err);
          });
      }, parseInt(debate.bufferTime) * 60000 || 60000);

      if (!isStarted || !isConnected) {
        clearTimeout(stop);
      }
      return false;
    }
    if (
      !imgArrVertical[usrIndex] ||
      imgArrVertical[usrIndex].length <= imgIndex
    ) {
      imgIndex = 0;
    }
    let interval =
      (timerAttVertical[usrIndex] * 1000) / imgArrVertical[usrIndex].length;
    console.log(
      "displayimgVertical",
      imgArrVertical[usrIndex][imgIndex].image,
      usrIndex,
      imgIndex,
      interval
    );
    setSrcVertical(imgArrVertical[usrIndex][imgIndex]);
    if (toogleaxios || !toogleaxiosHorizontal) {
      axios
        .post(`${config.apiUrl}/api/ads/showadslive`, {
          imageV: imgArrVertical[usrIndex][imgIndex].image,
          linkV: imgArrVertical[usrIndex][imgIndex].link,
          flagV: false,
          imageOrientation: "vertical",
        })
        .then((res) => {
          if (res.data) {
            settoggleaxios(true);
          } else {
            settoggleaxios(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    let stop = setTimeout(() => {
      if (imgArrVertical[usrIndex].length > imgIndex + 1) {
        displayimgVertical(usrIndex, imgIndex + 1);
      } else {
        displayimgVertical(usrIndex + 1, 0);
      }
    }, interval);

    if (!isStarted || !isConnected) {
      clearTimeout(stop);
    }
  }

  useEffect(() => {
    console.log(imgArrVertical, timerAttVertical);
    if (timerAttVertical.length > 0 && imgArrVertical.length > 0) {
      if (isStarted && isConnected) {
        displayimgVertical(0, 0);
      }
    }
  }, [
    timerAttVertical,
    imgArrVertical.length,
    isStarted,
    isConnected,
    imgArrVertical,
  ]);

  function displayimgHorizontal(usrIndex, imgIndex) {
    if (timerAttHorizontal.length <= usrIndex) {
      usrIndex = 0;
      imgIndex = 0;
      setToggleImgHorizontal(true);
      let stop = setTimeout(() => {
        console.log("bufferTime", debate.bufferTime);
        setToggleImgHorizontal(false);
        displayimgHorizontal(0, 0);
        //Reseting the ads value to its initial
        axios
          .post(`${config.apiUrl}/api/ads/showadslive`, {
            imageH: imgArrHorizontal[0][0].image,
            linkH: imgArrHorizontal[0][0].link,
            imageOrientation: "horizontal",
            flagH: true,
          })
          .then((res) => {
            settoggleaxiosHorizontal(false);
            console.log("Done", res.data);
            // alert('Hello we are done')
          })
          .catch((err) => {
            console.log(err);
          });
      }, parseInt(debate.bufferTime) * 60000 || 60000);

      if (!isStarted || !isConnected) {
        clearTimeout(stop);
      }
      return false;
    }
    if (
      !imgArrHorizontal[usrIndex] ||
      imgArrHorizontal[usrIndex].length <= imgIndex
    ) {
      imgIndex = 0;
    }
    let interval =
      (timerAttHorizontal[usrIndex] * 1000) / imgArrHorizontal[usrIndex].length;
    console.log(
      "displayimgHorizontal",
      imgArrHorizontal[usrIndex][imgIndex].image,
      usrIndex,
      imgIndex,
      interval
    );
    setSrcHorizontal(imgArrHorizontal[usrIndex][imgIndex]);
    if (toogleaxiosHorizontal || !toogleaxios) {
      axios
        .post(`${config.apiUrl}/api/ads/showadslive`, {
          imageH: imgArrHorizontal[usrIndex][imgIndex].image,
          linkH: imgArrHorizontal[usrIndex][imgIndex].link,
          flagH: false,
          imageOrientation: "horizontal",
        })
        .then((res) => {
          if (res.data) {
            settoggleaxiosHorizontal(true);
          } else {
            settoggleaxiosHorizontal(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    let stop = setTimeout(() => {
      if (imgArrHorizontal[usrIndex].length > imgIndex + 1) {
        displayimgHorizontal(usrIndex, imgIndex + 1);
      } else {
        displayimgHorizontal(usrIndex + 1, 0);
      }
    }, interval);

    if (!isStarted || !isConnected) {
      clearTimeout(stop);
    }
  }

  useEffect(() => {
    console.log(imgArrHorizontal, timerAttHorizontal);
    if (timerAttHorizontal.length > 0 && imgArrHorizontal.length > 0) {
      if (isStarted && isConnected) {
        displayimgHorizontal(0, 0);
      }
    }
  }, [timerAttHorizontal, imgArrHorizontal.length, isStarted, isConnected]);

  useEffect(() => {
    console.log(srcHorizontal, "---->", srcVertical, "--->");
  }, [srcHorizontal, srcVertical]);

  return (
    <>
      {activeRoom ? (
        <div>
          {/* {data && !toggleimgHorizontal && srcHorizontal?.image ? ( */}
          <div className="row p-0 m-0">
            <div className="col p-0 m-0">
              <div className="top-join-img">
                <div className="hederAdd p-0 m-0">
                  {data && !toggleimgHorizontal && srcHorizontal?.image ? (
                    <Link target="_blank" to={srcHorizontal?.link}>
                      <img
                        src={config.apiUrl + "/" + srcHorizontal?.image}
                        alt=""
                        className="rightImg"
                      />
                    </Link>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {/* ) : null} */}
          <div className="row p-0 m-0">
            <div className="col-11 p-0 m-0">
              <div className="App wrapper">
                <Room withads={{ toggleimgHorizontal, toggleimgVertical }} />
              </div>
            </div>
            {/* {!toggleimgVertical && data && srcVertical?.image ? ( */}
            <div className="col-1 p-0 m-0">
              <div className="right-join-img">
                <div className="hederAdd p-0 m-0">
                  {!toggleimgVertical && data && srcVertical?.image ? (
                    <Link target="_blank" to={srcVertical?.link}>
                      <img
                        src={config.apiUrl + "/" + srcVertical?.image}
                        alt=""
                        className="rightImg"
                      />
                    </Link>
                  ) : null}
                </div>
              </div>
            </div>
            {/* ) : null} */}
          </div>
        </div>
      ) : (
        <WaitingRoom data={data} />
      )}
    </>
  );
}

export default SingleDebate;
