import {
  VideocamOutlined,
  VideocamOffOutlined,
  MicNoneOutlined,
  MicOffOutlined,
  LogoutOutlined,
  PodcastsOutlined,
  StopCircleOutlined,
  ChatBubble,
  ChatOutlined as Chat,
  RadioButtonChecked as Radio,
  RadioButtonCheckedOutlined,
} from "@mui/icons-material";
import { Tooltip, IconButton, Button } from "@mui/material";
import {
  selectHLSState,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import {
  selectIsLocalAudioEnabled,
  selectIsLocalVideoEnabled,
  selectLocalPeer,
} from "@100mslive/react-sdk";
import { selectIsConnectedToRoom } from "@100mslive/react-sdk";
import React, { useState, useMemo, useEffect } from "react";
import { AuthState } from "../../context/auth";
import config from "../../config";

function Controls({ chat, toggleChat }) {
  const hmsActions = useHMSActions();
  const hlsState = useHMSStore(selectHLSState);
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const audioEnabled = useHMSStore(selectIsLocalAudioEnabled);
  const videoEnabled = useHMSStore(selectIsLocalVideoEnabled);
  const localPeer = useHMSStore(selectLocalPeer);
  const [recordEnabled, setRecordEnabled] = useState(false);
  const [statingStreaming, setStatingStreaming] = useState(false);

  const { myProfile, isSignedIn } = AuthState();


  console.log("audioEnabled", audioEnabled);

  const isAdmin = useMemo(() => {
    return isSignedIn && myProfile.role.name === "admin" ? true : false;
  }, [myProfile, isSignedIn]);

  const startHLSStreaming = async () => {
    try {
      console.log("statingStreaming", statingStreaming);
      if (!statingStreaming) {
        setStatingStreaming(true);
        const params = {
          variants: [
            {
              meetingURL: config.meetingUrl(config.roomId()),
              metadata: "landscape",
            },
          ],
        };
        if (isAdmin && recordEnabled) {
          params.recording = { singleFilePerLayer: true, hlsVod: false }; // to enable recording
        }
        await hmsActions.startHLSStreaming(params);
      }
    } catch (err) {
      // alert(`failed to start hls ${err}`);
    }
  };

  const stopHLSStreaming = async () => {
    try {
      console.log("statingStreaming", statingStreaming);
      if (hlsState.running) {
        await hmsActions.stopHLSStreaming();
        setStatingStreaming(false);
      }
    } catch (err) {
      setStatingStreaming(false);
      // alert(`failed to stop hls ${err}`);
    }
  };

  const toggleAudio = async () => {
    try {
      await hmsActions.setLocalAudioEnabled(!audioEnabled);
    } catch (err) {
      alert("permission to access capture audio device");
    }
  };

  const toggleVideo = async () => {
    try {
      await hmsActions.setLocalVideoEnabled(!videoEnabled);
    } catch (err) {
      alert("permission to capture video device");
    }
  };

  const toggleRecord = async () => {
    setRecordEnabled(!recordEnabled);
  };

  const leaveRoom = async () => {
    try {
      if (isConnected) {
        // await hmsActions.setLocalAudioEnabled(false);
        // await hmsActions.setLocalVideoEnabled(false);
        if (isAdmin) {
          if (localPeer && localPeer.roleName === "broadcaster") {
            if (hlsState && hlsState.running) {
              await hmsActions.stopHLSStreaming();
            }
          }
        }
        await hmsActions.leave();
      }
    } catch (err) {
      alert(err);
    }
  };

  // useEffect(() => {
  //   window.addEventListener("beforeunload", leaveRoom);
  //   window.addEventListener("onunload", leaveRoom);
  //   return () => {
  //     window.removeEventListener("onunload", leaveRoom);
  //     window.removeEventListener("beforeunload", leaveRoom);
  //   };
  // }, []);

  return (
    <div className="controls">
      {localPeer && localPeer?.roleName === "broadcaster" ? (
        <>
          <Tooltip title="Audio">
            <IconButton
              onClick={toggleAudio}
              color={audioEnabled ? "primary" : "error"}
              className={audioEnabled ? "audio-enable" : "audio-disable"}
            >
              {audioEnabled ? <MicNoneOutlined /> : <MicOffOutlined />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Video">
            <IconButton
              onClick={toggleVideo}
              color={videoEnabled ? "primary" : "error"}
              className={
                videoEnabled ? "videoicon-enable" : "videoicon-disable"
              }
            >
              {videoEnabled ? <VideocamOutlined /> : <VideocamOffOutlined />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Leave Room">
            <Button
              color="error"
              variant="contained"
              disableElevation
              className="leave"
              onClick={leaveRoom}
            >
              <LogoutOutlined /> Leave Room
            </Button>
          </Tooltip>

          {isAdmin ? (
            hlsState.running ? (
              <Tooltip title="Stop Streaming">
                <Button
                  variant="contained"
                  disableElevation
                  className="stop-streaming"
                  onClick={stopHLSStreaming}
                >
                  <StopCircleOutlined /> Stop Streaming
                </Button>
              </Tooltip>
            ) : statingStreaming ? (
              <Button
                variant="contained"
                disableElevation
                onClick={stopHLSStreaming}
                className="loading"
              >
                <PodcastsOutlined /> Loading
              </Button>
            ) : (
              <Tooltip title="Go Live">
                <Button
                  variant="contained"
                  disableElevation
                  className="goLive-disable"
                  onClick={startHLSStreaming}
                >
                  <PodcastsOutlined /> Go Live
                </Button>
              </Tooltip>
            )
          ) : null}
          {isAdmin ? (
            <Tooltip title="Record the stream">
              <Button
                disableElevation
                variant="contained"
                onClick={toggleRecord}
                className={recordEnabled ? "record-enable" : "record-disable"}
              >
                {recordEnabled ? <RadioButtonCheckedOutlined /> : <Radio />}
                {recordEnabled ? "Stop Recording" : "Enable Recording"}
              </Button>
            </Tooltip>
          ) : null}

          <Tooltip title="Chat">
            <IconButton
              onClick={toggleChat}
              color={chat ? "primary" : "info"}
              className={chat ? "chat-enable" : "chat-disable"}
            >
              {chat ? <ChatBubble /> : <Chat />}
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <Button
          variant="contained"
          disableElevation
          className="leave"
          onClick={leaveRoom}
        >
          <LogoutOutlined /> Leave Room
        </Button>
      )}
    </div>
  );
}

export default Controls;
