import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import { useQuery } from "react-query";
import config from "../config";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [isSignedIn, setIsSignedIn] = useState(!!localStorage.getItem("turn2speak_auth_token"));

    const { data: myProfile, isLoading } = useQuery(
        ["myProfile"],
        async () => {
            const url = `${config.apiUrl}/api/user/myProfile`;
            const { data } = await axios.get(url, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem(
                        "turn2speak_auth_token"
                    )}`,
                },
            });
            localStorage.setItem("turn2speak_auth_user", JSON.stringify(data.data));
            return data.data;
        },
        {
            enabled: !!localStorage.getItem("turn2speak_auth_token"),
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        }
    );

    return (
        <AuthContext.Provider
            value={{
                myProfile,
                isLoading,
                isSignedIn,
                setIsSignedIn,
            }}
        >
            {isSignedIn ? !isLoading ? children : <div className="loading">Loading ...</div> : children}
        </AuthContext.Provider>
    );
};

export const AuthState = () => useContext(AuthContext);
