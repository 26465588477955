import React from 'react'
import ContactBannerSection from './ContactBannerSection/ContactBannerSection'
import ContactSection from './ContactSection/ContactSection'

const Contact = () => {
  return (
    <>
        <ContactBannerSection/>
        <ContactSection/>
    </>
  )
}

export default Contact