import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useQuery } from 'react-query'
import config from '../../../config'

const TermsConditionsTurnLeftSection = () => {
  const [desc, setDesc] = useState([])
  const matchregx =/^[^\d]*/;
  useEffect(() => {
    axios
      .get(`${config.apiUrl}/api/tc/tc`)
      .then((res) => {
        if (res.status == 200) {
          console.log(res.data)
          const replaceN=res.data.data.split("\n")
          console.log(replaceN)
       
          setDesc(() =>replaceN)
        }
      })
      .catch((err) => {
        alert('Something went wrong')
        console.log(err?.message)
      })
  }, [])

  return (
    <>
      <section className="turnLeft">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <h2>Terms & Conditions</h2>
              <p>{desc.map((ele)=>{
                return <p> {ele} </p>
              
              })}</p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default TermsConditionsTurnLeftSection
