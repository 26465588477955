import { createContext, useState } from "react";



const MyTrialEnd=createContext()


const CustomTrialEndProvider=({children})=>{
    const [isTrial,setTrialEnd]= useState(false)
    return <MyTrialEnd.Provider value={{isTrial,setTrialEnd}}>{children}</MyTrialEnd.Provider>
}


export default CustomTrialEndProvider
export {MyTrialEnd}