import React from 'react'
import FooterCopyRightSection from './FooterCopyRightSection/FooterCopyRightSection';
import FooterLogoWithCaptionSection from './FooterLogoWithCaptionSection/FooterLogoWithCaptionSection';
import FooterQuickLinkSection from './FooterQuickLinkSection/FooterQuickLinkSection';
import FooterTopicsSection from './FooterTopicsSection/FooterTopicsSection';
import FooterContactUsSection from './FooterContactUsSection/FooterContactUsSection';



const footer = () => {
    return (
        <>
            <footer className="footerSec">
                <div className="container">
                    <div className="row">
                        <FooterLogoWithCaptionSection />

                        <FooterQuickLinkSection />

                        <FooterTopicsSection />

                        {/* <FooterContactUsSection /> */}
                    </div>

                    <FooterCopyRightSection />
                </div>
            </footer>
        </>
    );
};

export default footer;