import axios from 'axios'
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk'
import config from '../../config'
import { useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'

export default function Subscriptionpayment() {
  const { price,duration } = useParams()
  const nav = useNavigate()
  const data = JSON.parse(localStorage.getItem('turn2speak_auth_user'))
  

  const SubscriptionUser = async () => {
    console.log('Myuserdata', data)
    // axios
    //   .post(`${config.apiUrl}/api/payment/subscription`,{id:data._id,plan:price,noofdays:(parseInt(duration)*28)})
    //   .then((res) => {
    //     console.log(res.data)
    //     window.location.assign("/");

    //   })
    //   .catch((err) => {
    //     console.log('Error', err.message)
    //   })
  }



return (
    <div className="payment-section py-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8 col-sm-10 col-12">
            <div className="payment-box">
              <div className="mb-4 card-box">
                <img src="../../images/card-img.png" />
                <span>
                  {data?.firstName} {data?.lastName}
                </span>
              </div>
              <PaymentForm
                applicationId="sandbox-sq0idb-2mvSDK82lt1Q-BPD-wz-sw"
                cardTokenizeResponseReceived={async (token, buyer) => {
                  console.info({ token, buyer })
                  console.log('token', token.token)
                  const response = await axios.post(
                    `${config.apiUrl}/api/payment/pay`,
                    { sourceId: token.token, amt:price },
                    { headers: { 'Content-Type': 'application/json' } },
                  )
                  console.log('==>', response.data.data)

                  if (response.data.data.status == 'COMPLETED') {
                    nav('/success')
                    
                    // SubscriptionUser()



                    setTimeout(() => {
                      // nav('/')
                    }, 3000)
                  }
                }}
                //   Billing address

                locationId="LAXJ797WKTD55" //Will get from dashboard
              >
                <CreditCard />
              </PaymentForm>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
