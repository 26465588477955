import React from 'react'
import { useQueryClient } from "react-query";
import config from "../../config";

const LiveStream = () => {
    const queryClient = useQueryClient();
    const about = queryClient.getQueryData("About");

    return (
        <>

            <section className="liveStrem liveSesonSec">
                <div className="container">

                    <div className="row">
                        <div className="col-md-6">
                            <img src={config.apiUrl +"/"+ about?.imageUrl2} alt={about?.imageUrl2} />
                        </div>

                        <div className="col-md-6">
                            <h3 className="text-start mb-4 p-0">{about?.title2}</h3>
                            <p>{about?.description2}</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LiveStream