import React from 'react'
import { NavLink } from 'react-router-dom'
import DebateList from './DebateList';
import DebateVideoList from './DebateVideoList';

const DebateSction = () => {
  return (
    <>
      <section className="liveSesonSec">
        <div className="container">
          <div className="row">


            <div className="col-md-9">
              <DebateList />

              <div className="text-center">
                <NavLink to="/debates" className="viewAll">
                  View All
                </NavLink>
              </div>
              
            </div>


            <div className="col-md-3">
              <DebateVideoList />
            </div>

          </div>


          {/* <div className="chatBottom1">
            <NavLink to="#" className="">
              <img src="/images/chatNow.png" alt="" />
            </NavLink>
          </div> */}

        </div>
      </section>
    </>
  )
}

export default DebateSction