import React, { useCallback, useEffect } from "react";
import { selectIsConnectedToRoom } from "@100mslive/react-sdk";
import { useHMSActions, useHMSStore } from "@100mslive/react-sdk";

import Room from "./Room";
import AutoJoin from "./AutoJoin";

const AuthView = ({ liveStreams }) => {
    const isConnected = useHMSStore(selectIsConnectedToRoom);
    const hmsActions = useHMSActions();

    // const leaveRoom = useCallback(async () => {
    //     if (isConnected) {
    //         hmsActions.leave();
    //     }
    // }, [hmsActions, isConnected]);

    const leaveRoom = async () => {
        if (isConnected) {
            await hmsActions.leave();
        }
    };

    useEffect(() => {
        window.addEventListener("beforeunload", leaveRoom);
        window.addEventListener("onunload", leaveRoom);
        return () => {
            window.removeEventListener("onunload", leaveRoom);
            window.removeEventListener("beforeunload", leaveRoom);
        };
    }, []);

    return isConnected ? (
        <Room liveStreams={liveStreams} />
    ) : (
        <AutoJoin liveStreams={liveStreams} />
    );
};

export default React.memo(AuthView);
