import React from "react";

const ContactSection = () => {
    return (
        <>
            <section className="contactSec py-5">
                <div className="container">
                    <div className="my-3">
                        <h2 className="contact-title">
                            <span>Send Us</span> Your Request
                        </h2>
                    </div>
                    <div className="row">
                        <div className="col-md">
                            <div role="form" className="" id="">
                                <div className="screen-reader-response"></div>
                                <form
                                    action=""
                                    method="post"
                                    className="wpcf7-form"
                                    novalidate="novalidate"
                                >
                                    <div className="rd-mailform">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label className="form-label">Your name*</label>
                                                    <br />
                                                    <span className=" your-name">
                                                        <input
                                                            type="text"
                                                            name="your-name"
                                                            value=""
                                                            size="40"
                                                            className=" form-control"
                                                            aria-required="true"
                                                            aria-invalid="false"
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label className="form-label">Your phone*</label>
                                                    <br />
                                                    <span className=" your-number">
                                                        <input
                                                            type="tel"
                                                            name="your-number"
                                                            value=""
                                                            size="40"
                                                            className=" form-control"
                                                            aria-required="true"
                                                            aria-invalid="false"
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">Your e-mail*</label>
                                                    <br />
                                                    <span className="">
                                                        <input
                                                            type="email"
                                                            name="your-email"
                                                            value=""
                                                            size="40"
                                                            className=" form-control"
                                                            aria-required="true"
                                                            aria-invalid="false"
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">Message</label>
                                                    <br />
                                                    <span className=" your-message">
                                                        <textarea
                                                            name="your-message"
                                                            cols="30"
                                                            rows="5"
                                                            className=" form-control"
                                                            aria-invalid="false"
                                                        ></textarea>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input
                                                    type="submit"
                                                    value="Send Message"
                                                    className="btn1 btn mt-2"
                                                />
                                                <span className=""></span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        {/* <div className="col-md">
                            <div className="contact-item">
                                <div className="icon-box">
                                    <i className="fa fa-phone"></i>
                                </div>
                                <div className="text-box">
                                    <h3>Call Us any time</h3>
                                    <p>
                                        <a href="tel:4043547093">404-354-7093</a>
                                    </p>
                                </div>
                            </div>
                            <div className="contact-item">
                                <div className="icon-box">
                                    <i className="fa fa-envelope"></i>
                                </div>
                                <div className="text-box">
                                    <h3>Mail</h3>
                                    <p>
                                        <a href="mailto:yourturn2speak.com">Yourturn2speak.com</a>
                                    </p>
                                </div>
                            </div>
                            <div className="contact-item">
                                <div className="icon-box">
                                    <i className="fa fa-map-marker"></i>
                                </div>
                                <div className="text-box">
                                    <h3>Our Address </h3>
                                    1234 LOREM IPSUM STREET, SUITE 123; ELIZABETH, NJ 01234
                                </div>
                            </div>
                        </div> */}
                    </div>
                    {/* <div className="row mb-5">
                        <div className="col-sm-12">
                            <h2 className="contact-title mb-3">
                                <span>Our</span> Location
                            </h2>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26360429.60363783!2d-113.7394846633017!3d36.243723493394334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sin!4v1669636868126!5m2!1sen!2sin"
                                width="100%"
                                height="100%"
                                style={{ border: 0 }}
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </div>
                    </div> */}
                </div>
            </section>
        </>
    );
};

export default ContactSection;
