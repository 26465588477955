import React from 'react'

const FooterCopyRightSection = () => {
  return (
    <>
      <div className="copyRight">
        © Copyright 2023 - All rights reserved
      </div>
    </>
  )
}

export default FooterCopyRightSection