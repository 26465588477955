import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import config from '../../config'
import axios from 'axios'
import { Query, useQuery } from 'react-query'
import { MyAdsCheck } from '../../context/adsProtection'

export default function ViewAds() {
  const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    // { id: 'company', label: 'Email', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'duration', label: 'Duration(sec)', alignRight: false },
    { id: 'amount', label: 'Amount($)', alignRight: false },
    { id: 'phone', label: 'Phone', alignRight: false },
    { id: 'title', label: 'Title', alignRight: false },
    { id: 'noImages', label: 'No of Images', alignRight: false },

  ]
  const [isLoading, setIsLoading] = useState(true)
  const [ads, setAds] = useState([])
  const { id } = useParams()
  const [userval]=useState(JSON.parse(localStorage.getItem("turn2speak_auth_user")))

  const fetchAllAds = () => {

    axios
      .post(`${config.apiUrl}/api/ads/useradsdetails/${id}`)
      .then((res) => {
        console.log('RES>>>', res.data.useradsdetails)
        setAds(res?.data?.useradsdetails)
        setIsLoading(false)
      })
      .catch((error) => {
        console.log('ERROR', error)
        setIsLoading(false)
      })
  }
 const adsdata=useContext(MyAdsCheck)
  
  useEffect(() => {


    console.log("ads data",adsdata)
    
    fetchAllAds()



    axios.post(`${config.apiUrl}/api/ads/assignuserid`,{email:userval.email,id:userval._id}).then(()=>{

  //  alert("Doneeeeee")
    }).catch((err)=>{
      console.log('err',err)
    })
    
  }, [])

  return (

  
        <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4"  gutterBottom>
          All your Ads
        </Typography>
        <Button variant="contained">
          <Link to="/ads">Create New Ad</Link>
        </Button>
      </Stack>
      <Card>
        <TableContainer sx={{ minWidth: 800 }}>
        {isLoading ? (
              <div className="m-2">
                <Box justifyContent={'center'} alignItems="center">
                  <CircularProgress />
                </Box>
              </div>
            ):
          ads.length == 0 ? (
            <p className="text-danger">No Ads Added </p>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((headCell) => (
                    <TableCell key={headCell.id}>
                      <TableSortLabel>{headCell.label}</TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {isLoading ? (
                <Box justifyContent={'center'} alignItems="center">
                  <CircularProgress />
                </Box>
              ) : (
                <TableBody>
                  {ads &&
                    ads?.length &&
                    ads?.map((ad, index) => {
                      const ads = ad.status
                      let adsValue = ''
                      if (ads === '0') {
                        adsValue = 'Rejected'
                      } else if (ads === '2') {
                        adsValue = 'Completed'
                      } else if (ads === '3') {
                        adsValue = 'Approved'
                      } else {
                        adsValue = 'Recently Added'
                      }
                      return (
                        <>
                          <TableRow hover tabIndex={-1} role="checkbox">
                            <TableCell
                              key={index}
                            >{`${ad?.firstName} ${ad?.lastName}`}</TableCell>
                            <TableCell key={index}>{adsValue}</TableCell>
                            <TableCell key={index}>{ad?.email}</TableCell>
                            <TableCell key={index}>{ad?.duration}</TableCell>
                            <TableCell key={index}>{ad?.package}</TableCell>
                            <TableCell key={index}>{ad?.phone}</TableCell>
                            <TableCell key={index}>{ad?.title}</TableCell>
                            <TableCell key={index}>
                              {ad?.adsImages.length}
                            </TableCell>
                            <TableCell key={index}>
                              <Box key={index}>
                                <Link to={`/viewads/adsDetails/${ad._id}`}>
                                  <Button>Click Me to View</Button>
                                </Link>
                              </Box>
                            </TableCell>
                            <TableCell key={index}>
                              {/* <AdsMoreMenu user={ads} setSelectedUser={setSelectedUser} handleOpen={handleOpen} /> */}
                            </TableCell>
                          </TableRow>
                        </>
                      )
                    })}
                </TableBody>
              )}
            </Table>
          )}
        </TableContainer>
      </Card>
    </Container>

  
  )
}
