import React from "react";
import moment from 'moment';
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import axios from "axios";

import config from "../../config";

import Video from "./Video";

const Watch = () => {
  const { watchid } = useParams();

  console.log(watchid);

  const { data: watch, isLoading } = useQuery(
    ["watch", watchid],
    async () => {
      const url = `${config.apiUrl}/api/videos?id=${watchid}`;
      const { data } = await axios.get(url);
      return data.data;
    },
    {
      enabled: !!watchid,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  return (
    !isLoading ?
      <section>
        <Video src={watch.path} layers={watch.layers} watch={watch} />
        <div className="vdoSec">
          <div className="p-5 bg-light text-muted rounded">
            <h3>{watch.debate?.title}</h3>
            <p><small>Date: {moment(watch.debate?.createdAt).format('lll')}</small></p>
            <p>{watch.debate?.description}</p>
          </div>
        </div>
      </section>
      : null
  );
};

export default Watch;
