import React from 'react'
import { useNavigate } from 'react-router-dom'

const NavbarLogoSection = () => {

    const navigate = useNavigate()

    return (
        <>
            <a className="navbar-brand" onClick={() => navigate('/')}>
                <img src="/images/logo.png" alt="" />
            </a>
        </>
    )
}

export default NavbarLogoSection