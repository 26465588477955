import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { useQuery } from 'react-query'

import config from '../../config'

import Banner from '../../components/Banner/Banner'
import TurnLeft from '../../components/TurnLeft/TurnLeft'
import LiveStream from '../../components/LiveStream/LiveStream'
import DebateSction from '../../components/DebateSction'
import { Navigate, useNavigate } from 'react-router-dom'
// import { MyAdsContext } from '../../context/adscontext'
import { MyAdsCheck } from '../../context/adsProtection'
import { MyTrialEnd } from '../../context/trialend'

const Home = () => {
  useQuery(
    ['About'],
    async () => {
      const url = `${config.apiUrl}/api/about`
      const { data } = await axios.get(url)
      return data.data[0]
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnmount: false,
      retry: false,
    },
  )

  const navigate = useNavigate()
  const adsdata = useContext(MyAdsCheck)
  const [userval] = useState(
    JSON.parse(localStorage.getItem('turn2speak_auth_user')),
  )
  const trielcheck = useContext(MyTrialEnd)

  useEffect(() => {
    if (userval) {
      if (!userval.role.name == 'admin') {
        if (userval.planstart == userval.planend) trielcheck.setTrialEnd(true)
        navigate('/pricing')
      }
      // console.log('bbhb', adsdata)
      axios
        .post(`${config.apiUrl}/api/ads/assignuserid`, {
          email: userval.email,
          id: userval._id,
        })
        .then(() => {
          if (userval.role.name == 'adsUser') {
            navigate(`viewads/${userval._id}`)
            adsdata.setRoleName({ id: userval._id, role: 'adsUser' })

            // navigate('/')
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    }

    // }
  }, [])
  return (
    <>
      <Banner />

      <TurnLeft />

      <LiveStream />

      <DebateSction />
    </>
  )
}

export default Home
