import axios from "axios";
import React, { useCallback, useMemo, memo } from "react";
import { useHMSActions } from "@100mslive/react-sdk";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";

import { AuthState } from "../../context/auth";
import Comments from "./Comments";
import Participate from "./Participate";
import config from "../../config";

// const user = JSON.parse(localStorage.getItem('turn2speak_auth_user'));

function WaitingRoom({ data }) {
    const hmsActions = useHMSActions();

    const { debateId } = useParams();
    const { myProfile, isSignedIn } = AuthState();

    const debate = data && data?.debate;
    const users = data && data?.users;

    const isAdmin = useMemo(() => {
        return !myProfile ? false : !myProfile.role ? false : myProfile.role.name === "admin" ? true : false
    }, [myProfile]);

    const isStarted = useMemo(() => {
        return debate?.isStarted ? true : false;
    }, [debate]);

    const { mutate } = useMutation(
        async (v) => {
            await axios.post(
                `${config.apiUrl}/api/user/debateRequest`,
                {
                    debateId: v.debateId,
                    endTime: v.endTime,
                },
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem(
                            "turn2speak_auth_token"
                        )}`,
                    },
                }
            );
        },
        {
            onSuccess: () => {
                alert("Your request has been sent. Please wait...");
                // queryClient.refetchQueries(["singleDebate", debateId])
            },
        }
    );

    const dDate = useMemo(() => {
        // const startTime = new Date(debate?.startDate).getHours ()
        const endTime =
            new Date(debate?.startDate).getTime() + debate?.duration * 60000;
        const endDate = new Date(endTime);

        var shours = new Date(debate?.startDate).getHours();
        var sminutes = new Date(debate?.startDate).getMinutes();
        var ehours = new Date(endDate).getHours();
        var eminutes = new Date(endDate).getMinutes();

        // Check whether AM or PM
        var snewformat = shours >= 12 ? "PM" : "AM";
        var enewformat = ehours >= 12 ? "PM" : "AM";

        // Find current hour in AM-PM Format
        shours = shours % 12;
        ehours = ehours % 12;

        // To display "0" as "12"
        shours = shours ? shours : 12;
        sminutes = sminutes < 10 ? "0" + sminutes : sminutes;
        ehours = ehours ? ehours : 12;
        eminutes = eminutes < 10 ? "0" + eminutes : eminutes;

        const startTime = shours + ":" + sminutes + " " + snewformat;
        const endT = ehours + ":" + eminutes + " " + enewformat;

        return { startTime, endT, endTime };
    }, [debate]);

    const handelPerticipateDebate = useCallback(async () => {
        try {
            if (isSignedIn) {
                if (dDate.endTime < new Date().getTime()) {
                    alert("Debate has ended");
                    return;
                } else {
                    mutate({ debateId, endTime: dDate.endTime });
                }
            } else {
                alert("please sign in before perticipate");
            }
        } catch (error) {
            console.error(error);
        }
    }, [debateId, dDate, mutate, isSignedIn]);

    const handelJoinDebate = useCallback(async () => {
        var joinDebate = false;
        if (!isStarted) {
            alert("Debate has not started yet");
            return;
        }
        if (!isAdmin) {
            if (
                dDate.endTime < new Date().getTime() ||
                new Date().getTime() < new Date(debate?.startDate).getTime()
            ) {
                alert("Please join within the debate shedule");
                return;
            }
            const { data: dData } = await axios.get(
                `${config.apiUrl}/api/user/checkPerticipation/${debateId}`,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem(
                            "turn2speak_auth_token"
                        )}`,
                    },
                }
            );
            if (dData?.status && isSignedIn) {
                joinDebate = true;
            }
        } else {
            joinDebate = true;
        }
        if (!joinDebate) {
            alert("You are not authorized to join this debate.");
            return;
        }
        const { data } = await axios.get(
            `${config.apiUrl}/api/user/hms/room/${debate.roomId}/authtoken`,
            {
                headers: {
                    authorization: `Bearer ${localStorage.getItem(
                        "turn2speak_auth_token"
                    )}`,
                },
            }
        );
        if (data?.token && data.token) {
            config.setRoomId(debate?.roomId);

            // Joining the room
            hmsActions.join({
                userName: myProfile?.username,
                authToken: data.token,
                settings: {
                    isAudioMuted: true,
                    isVideoMuted: true,
                },
            });
        }

    }, [isStarted, dDate, debateId, isSignedIn, hmsActions, myProfile]);

    const isInDebate = useMemo(() => {
        return !myProfile ? false : data?.users?.some((u) => u?.userId?._id === myProfile?._id)
    }, [data?.users, myProfile]);

    return (
        <div>
            <section className="bannerSec innerBanner">
                <div
                    className="box-position"
                    style={{ background: "url(/images/innerBanner.jpg)" }}
                ></div>
                <div className="bannerTxt">
                    <h1>Debate</h1>
                </div>
            </section>

            <section className="turnLeft my-5 debateSecDetails">
                <div className="container">
                    <div className="eventBx shadow mb-4">
                        <div className="evntTxt">
                            <h5>{debate?.title}</h5>
                            <hr />

                            <p>{debate?.description}</p>
                            <hr />
                            <div>
                                {/* <h6>
                                    Participate <small>(9 Members)</small>
                                </h6>

                                <ul className="memberList">
                                    <li>
                                        <img src="/images/l2.jpg" alt="" />
                                    </li>
                                    <li>
                                        <img src="/images/l3.jpg" alt="" />
                                    </li>
                                    <li>
                                        <img src="/images/li.jpg" alt="" />
                                    </li>
                                    <li>
                                        <img src="/images/tstimnl-img1.jpg" alt="" />
                                    </li>
                                    <li>
                                        <img src="/images/l2.jpg" alt="" />
                                    </li>
                                    <li>
                                        <img src="/images/l3.jpg" alt="" />
                                    </li>
                                    <li>
                                        <img src="/images/li.jpg" alt="" />
                                    </li>
                                    <li>
                                        <img src="/images/tstimnl-img1.jpg" alt="" />
                                    </li>
                                    <li>
                                        <img src="/images/l2.jpg" alt="" />
                                    </li>
                                </ul> */}

                                <Participate users={users}/>

                                <hr />
                                {isInDebate || isAdmin ? (
                                    <Button
                                        size="medium"
                                        variant="contained"
                                        color={isStarted ? "success" : "error"}
                                        onClick={handelJoinDebate}
                                    >
                                        Join
                                    </Button>
                                ) : (
                                    <Button
                                        size="medium"
                                        color="error"
                                        variant="contained"
                                        className="btn1"
                                        onClick={handelPerticipateDebate}
                                    >
                                        Confirm your participation{" "}
                                    </Button>
                                )}
                            </div>

                        </div>
                        {debate?.startDate && (
                            <div className="evntDate">
                                <strong>Debate Start</strong>
                                <div className="time">
                                    <i className="fa-regular fa-clock"></i> {dDate?.startTime}{" "}
                                    - {dDate?.endT}
                                </div>
                                <h4>{new Date(debate?.startDate).toLocaleDateString()}</h4>
                                <hr />
                            </div>
                        )}
                    </div>
                    {/* {isSignedIn ? <Comments /> : null} */}
                    <Comments />
                </div>

            </section>
        </div>
    );
}

export default WaitingRoom;
