import React from "react";
import HlsPlayer from "../../components/HLSPlayer";

function PublicView({ liveStreams }) {
    return (
        <div>
            {liveStreams.map((row, i) => (
                <HlsPlayer key={i} source={row.playback.url} islive={true} />
            ))}
        </div>
    );
}

export default React.memo(PublicView);
