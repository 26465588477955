import React from 'react'

const ContactBannerSection = () => {
    return (
        <>
            <section className="bannerSec innerBanner">
                <div className="box-position" style={{background:'url("/images/innerBanner.jpg")'}}>

                </div>
                <div className="bannerTxt">
                    <h1>Contact</h1>
                </div>
            </section>
        </>
    )
}

export default ContactBannerSection