import React, { useMemo } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import config from "../../config";

const DebateList = () => {

    const { data: latestDebates } = useQuery(
        ["latestDebates"],
        async () => {
            const url = `${config.apiUrl}/api/user/debates?limit=2`;
            const { data } = await axios.get(url);
            return data.debates;
        },
        {
            // enabled: !!isAuth,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        }
    );

    console.log(latestDebates);

    const dDate = (debate) => {
        // const startTime = new Date(debate?.startDate).getHours ()
        const endTime = new Date(debate?.startDate).getTime() + debate?.duration * 60000;
        const endDate = new Date(endTime);

        var shours = new Date(debate?.startDate).getHours();
        var sminutes = new Date(debate?.startDate).getMinutes();
        var ehours = new Date(endDate).getHours();
        var eminutes = new Date(endDate).getMinutes();

        // Check whether AM or PM
        var snewformat = shours >= 12 ? "PM" : "AM";
        var enewformat = ehours >= 12 ? "PM" : "AM";

        // Find current hour in AM-PM Format
        shours = shours % 12;
        ehours = ehours % 12;

        // To display "0" as "12"
        shours = shours ? shours : 12;
        sminutes = sminutes < 10 ? "0" + sminutes : sminutes;
        ehours = ehours ? ehours : 12;
        eminutes = eminutes < 10 ? "0" + eminutes : eminutes;

        const startTime = shours + ":" + sminutes + " " + snewformat;
        const endT = ehours + ":" + eminutes + " " + enewformat;

        return { startTime, endT, endTime };
    };

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    return (
        <>


            {/* <div className="eventBx shadow mb-4">
                <div className="evntImg">
                    <img src="/images/video3.png" alt="" />
                </div>
                <div className="evntTxt">
                    <h5>live Session Name</h5>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. it to make a type specimen
                        book.
                    </p>
                </div>
                <div className="evntDate">
                    <div className="time">
                        <i className="fa-regular fa-clock"></i> 9am - 7pm
                    </div>
                    <p>02</p>
                    <h4>November</h4>
                    <h3>2022</h3>
                </div>
            </div>

            <div className="eventBx shadow mb-4">
                <div className="evntImg">
                    <img src="/images/video3.png" alt="" />
                </div>
                <div className="evntTxt">
                    <h5>live Session Name</h5>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. it to make a type specimen
                        book.
                    </p>
                </div>
                <div className="evntDate">
                    <div className="time">
                        <i className="fa-regular fa-clock"></i> 9am - 7pm
                    </div>
                    <p>02</p>
                    <h4>November</h4>
                    <h3>2022</h3>
                </div>
            </div> */}


            {latestDebates &&
                latestDebates.map((row, i) => (
                        row.deleteStatus=="inactive"&& <div key={i} className="vdoLive">
                        <div className="eventBx shadow mb-4">
                            <div className="evntImg">
                                {row.imageUrl ? <img src={`${config.apiUrl}/${row.imageUrl}`} alt="" /> : <img src="images/video3.png" alt="" />}
                            </div>
                            <div className="evntTxt">
                                <h5>{row.title}</h5>
                                <p>{row.description}</p>
                            </div>
                            <div className="evntDate">
                                <div className="time">
                                    <i className="fa-regular fa-clock"></i> {dDate(row)?.startTime} - {dDate(row)?.endT}
                                </div>
                                <p>{new Date(row?.startDate).getDate()}</p>
                                <h4>{months[new Date(row?.startDate).getMonth()]}</h4>
                                <h3>{new Date(row?.startDate).getFullYear()}</h3>
                            </div>
                        </div>
                    </div>
                    
                
                  
                ))
            }

        </>
    )
}

export default DebateList