import React from "react";
import BannerSection from "./BannerSection/BannerSection";
import PricingTableSection from "./PricingTableSection/PricingTableSection";

export default function Pricing() {
  return (
    <>
      <BannerSection />
      <PricingTableSection />
    </>
  );
}
