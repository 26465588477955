import React from "react";

const TopicBannerSection = ({ topic }) => {
    return (
        <>
            <section className="bannerSec innerBanner">
                <div
                    className="box-position"
                    style={{
                        background: `url(${topic?.imageUrl || "images/innerBanner.jpg"})`,
                    }}
                ></div>
                <div className="bannerTxt">
                    <h1>{topic.name.toUpperCase()}</h1>
                </div>
            </section>
        </>
    );
};

export default TopicBannerSection;
