import React from 'react'
import { Link } from 'react-router-dom'

const FooterQuickLinkSection = () => {
  return (
    <>
      <div className="col-md">
        <h2>Quick Link</h2>
        <ul className="qkLinnk ">
          <li>
            <Link to="/">HOME</Link>
          </li>
          <li>
            <Link to="/about">ABOUT</Link>
          </li>
          <li>
            <Link to="/termsconditions">TERMS & CONDITIONS</Link>
          </li>
          <li>
            <Link to="/testimonials">TESTIMONIALS</Link>
          </li>
          <li>
            <Link to="/contact">CONTACT</Link>
          </li>

        </ul>
      </div>
    </>
  )
}

export default FooterQuickLinkSection