import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { AuthState } from "../context/auth";

function UnAuth({ children }) {
    const [userval]=useState(JSON.parse(localStorage.getItem("turn2speak_auth_user")))
    console.log("My user",userval)

    const { isSignedIn } = AuthState();
    if (isSignedIn) {
      
            return <Navigate to="/" replace />;
    }
    return children;
}
export default UnAuth;
