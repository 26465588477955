import React, { memo } from "react";
import Plyr from "plyr-react";
import "plyr-react/plyr.css";
import config from "../../config";

const sizes = ["240", "360", "480", "720", "1080"];

const Video = ({ src, layers, watch }) => {
    console.log(watch);

    // https://github.com/sampotts/plyr#the-source-setter
    // https://github.com/sampotts/plyr#options
    const plyrProps = {
        source: {
            type: "video",
            title: "Example title",
            sources: layers.map((v, i) => {
                return {
                    src: config.s3ToObjectUrl(v),
                    type: "video/mp4",
                    size: sizes[i],
                };
            }),
            poster: "/images/v1.png",
        },
        options: undefined,
        // Direct props for inner video tag (mdn.io/video)
    };
    return <Plyr {...plyrProps} />;
};

export default memo(Video);
