import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import NavbarLogoSection from "./NavbarLogoSection/NavbarLogoSection";
import NavbarNavigationMenuSection from "./NavbarNavigationMenuSection/NavbarNavigationMenuSection";
import NavbarLoginSignupSection from "./NavbarLoginSignupSection/NavbarLoginSignupSection";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import Notifications from "../../pages/Debate/Notifications";

const Navbar = () => {
    const [open, setOpen] = useState(false);
    const [userDetails]=useState(JSON.parse(localStorage.getItem("turn2speak_auth_user")))
    const [userId,setUserId]=useState("")

    function handleNav() {
        setOpen(!open);
    }

    const getId=()=>{
        setUserId(userDetails._id)


    }

    function navClass() {
        return !open ? "collapse navbar-collapse" : "collapse navbar-collapse show";
    }

    return (
        <>
            <header className="headerSec">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light ">
                        <div className="container-fluid">
                            <NavbarLogoSection />
                            <button
                                className="navbar-toggler"
                                type="button"
                                // data-bs-toggle="collapse"
                                // data-bs-target="#navbarSupportedContent"
                                // aria-controls="navbarSupportedContent"
                                // aria-expanded="false"
                                // aria-label="Toggle navigation"
                                onClick={handleNav}
                            >
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className={navClass()} id="navbarSupportedContent">
                                <NavbarNavigationMenuSection handleNav={handleNav} />
                                <div className="d-flex align-items-center">
                                    <NavLink
                                        onClick={handleNav}
                                        to="/live-session"
                                        className="live"
                                    >
                                        <img src="/images/live.png" alt="" />
                                    </NavLink>
                                    {localStorage.getItem("turn2speak_auth_token") ? (
                                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                            <Notifications handleNav={handleNav} />
                                            <li className="nav-item">
                                                <NavLink
                                                    onClick={handleNav}
                                                    to="/profile"
                                                    className="nav-link"
                                                >
                                                    <AccountCircleIcon />
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink
                                                    to="/"
                                                    className="nav-link"
                                                    onClick={() => {
                                                        handleNav();
                                                        localStorage.removeItem("turn2speak_auth_token");
                                                        localStorage.removeItem("turn2speak_auth_user");
                                                        localStorage.removeItem("data");
                                                        localStorage.removeItem("user");


                                                        window.location.assign("/");
                                                    }}
                                                >
                                                    <LogoutIcon />


                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                    <NavLink to={`viewads/${userDetails?._id}`} onClick={getId} className={'nav-link'}>
                        ViewAds
                    </NavLink>
                </li>
                                        </ul>
                                    ) : (
                                        <NavbarLoginSignupSection />
                                    )}
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        </>
    );
};

export default Navbar;
