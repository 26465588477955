import React from 'react'
import { NavLink, useLocation } from 'react-router-dom';

const NavbarLoginSignupSection = () => {
    const { pathname } = useLocation();
    return (
        <>
            <div className="btn-group">
                <NavLink to='/login' className="btn" aria-current="page">
                    Login
                </NavLink>
                <NavLink to='/signup' className="btn ">
                    Signup
                </NavLink>
                <NavLink to='/ads' className="btn ">
                    Ads
                </NavLink>
                
            </div>
        </>
    )
}

export default NavbarLoginSignupSection