import React from "react";

import TopicBannerSection from "./TopicBannerSection/TopicBannerSection";
import TopicContentSection from "./TopicContentSection/TopicContentSection";

const Topic = ({ topic }) => {
  return (
    <>
      <TopicBannerSection topic={topic} />
      <TopicContentSection topic={topic} />
    </>
  );
};

export default Topic;
