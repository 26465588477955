import { useState, useEffect } from 'react'
import { useVideo } from "@100mslive/react-sdk";
import React from "react";
import { useMemo } from "react";
import { useHMSNotifications } from '@100mslive/react-sdk';

import {
  useHMSActions,
} from "@100mslive/react-sdk";

import {
  MicNoneOutlined,
  MicOffOutlined,
} from "@mui/icons-material";

import { AuthState } from "../../context/auth";

function VideoTile({ peer, peers }) {
  console.log("peer", peer)
  const notification = useHMSNotifications();
  const hmsActions = useHMSActions();
  const { videoRef } = useVideo({
    trackId: peer.videoTrack,
  });

  const [showName, setShowName] = useState(true);
  const [audioEnabled, setaudioEnabled] = useState(true);

  const { myProfile, isSignedIn } = AuthState();
  const isAdmin = useMemo(() => {
    return isSignedIn && myProfile.role.name === "admin" ? true : false;
  }, [myProfile, isSignedIn]);


  const toggleAudio = async (e) => {
    try {
      await hmsActions.setRemoteTrackEnabled(peer.audioTrack, e);
      setaudioEnabled(() => e);
    } catch (error) {
      // Permission denied or invalid track ID or not connected to room
      console.error(error);
    }
  };
  const toggleAudios = async (id, end) => {
    try {
      await hmsActions.setEnabledTrack(id, end);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!notification) {
      return;
    } else {
      console.log("notification",  notification);
      if (notification.type === "TRACK_MUTED" || "TRACK_UNMUTED" || "TRACK_ADDED") {
        if(notification?.data && notification?.data?.type === "audio") {
          const { peerId, enabled } = notification?.data;
          // Unmute Request
          if(!isAdmin && peerId === peer.id && enabled !== audioEnabled) {
            toggleAudios(peerId, enabled);
          }
          if(isAdmin) {
            toggleAudios(peerId, enabled);
            setaudioEnabled(enabled)
          }
        }
      }
      if (notification.type === "CHANGE_TRACK_STATE_REQUEST") {
        if(notification?.data) {
          const { track, enabled } = notification?.data;
          toggleAudios(track.id, enabled);
        }
      }
    }


  }, [audioEnabled, notification]);

  return (
    <>
      {showName && <span>{String(peer.name[0]).toUpperCase()}</span>}
      <p>{peer.name}</p>
      {isAdmin && peer.isLocal === false ? <button
        onClick={() => toggleAudio(!audioEnabled)}
        color={audioEnabled ? "primary" : "error"}
        className={audioEnabled ? "audio-enable" : "audio-disable"}
      >
        {audioEnabled ? <MicNoneOutlined /> : <MicOffOutlined />}
      </button> : null}


      <video
        ref={videoRef}
        className={peers.length >= 2 ? "video" : "video"}
        autoPlay
        muted
        playsInline
        onPlay={(e) => { console.log(e); setShowName((c) => false) }}
        onPlaying={(e) => { console.log(e); setShowName((c) => false) }}
        onAbort={(e) => { console.log(e); setShowName((c) => true) }}
        onSuspend={(e) => { console.log(e); setShowName((c) => true) }}
      />
    </>
  );
}

export default VideoTile;
