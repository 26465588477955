import React from "react";
import BannerSection from "./BannerSection/BannerSection";
import SuccessSection from "./SuccessSection/SuccessSection";

export default function Success() {
  return (
    <>
      <BannerSection />
      <SuccessSection />
    </>
  );
}
