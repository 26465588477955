import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import config from '../../../config'

const RightAd = ({ src,liveStreams }) => {


  return (
    <>
      <div className="item">
        <div className="hederAdd p-0 m-0">
         
        {src && ! src[1]?.flagV ? <Link target="_blank" to={src.linkV}> <img src={`${config.apiUrl}/${src?.imageV}`} alt="" className="rightImg" />  </Link> : null}
         
        </div>
      </div>
    </>
  )
}

export default RightAd
