import React from 'react'
import TermConditionBannerSection from './TermsConditionsBannerSection/TermsConditionsBannerSection'
import TermConditionTurnLeftSection from './TermsConditionsTurnLeftSection/TermsConditionsTurnLeftSection'

const Termsconditions = () => {
  return (
    <>
    <TermConditionBannerSection/>
    <TermConditionTurnLeftSection/>
    </>
  )
}

export default Termsconditions