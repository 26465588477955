import React from 'react'
import { useQueryClient } from 'react-query';
import { NavLink } from 'react-router-dom';

const NavbarNavigationMenuSection = ({ handleNav }) => {
    // const [active, setActive] = useState(true);
    // const { pathname } = useLocation();
    const queryClient = useQueryClient()
    const topics = queryClient.getQueryData('allTopics')
    // console.log('--topics---', topics);
    return (
        <>
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                    <NavLink onClick={handleNav} to='/' className={'nav-link'} aria-current="page">
                        HOME
                    </NavLink>
                </li>
                {/* <li className="nav-item">
                    <NavLink onClick={handleNav} to='/about' className={'nav-link'}>
                        ABOUT
                    </NavLink>
                </li> */}
                {topics && topics.filter(t => t.showOnMenu).sort(function (a, b) { return a.positionOnMenu - b.positionOnMenu }).map(t => (
                    <li className="nav-item" key={t._id}>
                        <NavLink onClick={handleNav} key={t._id} to={`/topic/${t._id}`} className={'nav-link'}>
                            {t.name.toUpperCase()}
                        </NavLink>
                    </li>
                ))}
               
                {/* <li className="nav-item">
                    <NavLink to='/crime' className="nav-link" isactive={() => ['/crime'].includes(pathname)}>
                        CRIME
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to='/economics' className="nav-link" isactive={() => ['/economics'].includes(pathname)}>
                        ECONOMICS
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to='/sports' className="nav-link" isactive={() => ['/sports'].includes(pathname)}>
                        SPORTS
                    </NavLink>
                </li> */}
            </ul>
        </>
    )
}

export default NavbarNavigationMenuSection