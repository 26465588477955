import { useState, useCallback, memo, useEffect } from 'react'
import Stream from './Stream'
import ChatNdParticipants from './ChatNdParticipants'
import Controls from './Controls'

function Room({ withads }) {
  console.log('Room', withads)
  const [chat, setChat] = useState(false)

  const toggleChat = useCallback(() => {
    setChat((current) => !current)
    console.log('chat')
  }, [])

  useEffect(() => {
    // alert("Now we will show ads")
    // refetch()
    // displayads(true)
    // setTriggerAds(true)
  }, [])

  return (
    <div
      className={
        !withads?.toggleimgHorizontal || !withads?.toggleimgVertical
          ? 'mainroom mainroom-withads'
          : 'mainroom'
      }
    >
      <div className="room__streamSpace">
        <Stream />
        <Controls chat={chat} toggleChat={toggleChat} />
      </div>
      <ChatNdParticipants chat={chat} setChat={setChat} />
    </div>
  )
}

export default Room
