import React from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { useQuery } from "react-query";
import config from "../../config";

const Banner = () => {
    const { data: banner, isLoading } = useQuery(
        ["banner"],
        async () => {
            const url = config.apiUrl + "/api/banner";
            const { data } = await axios.get(url);
            return data.data[0];
        },
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        }
    );

    const imageUrl = React.useMemo(() => {
        if (!isLoading && banner && banner.imageUrl) {
            return JSON.parse(banner.imageUrl);
        }
        return [];
    }, [banner, isLoading]);

    return (
        <>
            {imageUrl.length ? (
                <section className="bannerSec">
                    <div
                        id="carouselExampleIndicators"
                        className="carousel slide"
                        data-bs-ride="carousel"
                    >
                        <div className="carousel-indicators">
                            {imageUrl.map((row, i) => {
                                return (
                                    <button
                                        key={i}
                                        type="button"
                                        data-bs-target="#carouselExampleIndicators"
                                        data-bs-slide-to={i}
                                        aria-current="true"
                                        aria-label={`Slide ${i}`}
                                        className={i ? null : "active"}
                                    ></button>
                                );
                            })}
                        </div>
                        <div className="carousel-inner">
                            {imageUrl.map((row, i) => {
                                return (
                                    <div
                                        key={i}
                                        className={i ? "carousel-item" : "carousel-item  active"}
                                    >
                                        <img
                                            src={config.apiUrl + "/" + row}
                                            className="d-block w-100"
                                            alt={row}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="bannerTxt">
                        <div className="bannerTxtInner">
                            <img src="images/logo_tri.png" alt="" />
                            <h1>{banner?.title}</h1>
                            <NavLink
                                to="/debates"
                                className={"btn1 glow-on-hover"}
                                aria-current="page"
                            >
                                Click to join Debate
                            </NavLink>
                        </div>
                    </div>
                </section>
            ) : null}
        </>
    );
};
export default Banner;
