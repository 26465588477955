import React from 'react'

const FooterContactUsSection = () => {
  return (
    <>
      <div className="col-md-4">
        <h2>Contact Us</h2>
        <div className="adresBx">
          <h5>Call Us</h5>
          <h6><i className="las la-phone-volume"></i><a href="tel:4043547093" className=""> 404-354-7093</a> </h6>
        </div>

        <div className="adresBx">
          <h5>Email Us</h5>
          <h6><i className="las la-phone-volume"></i> <a href="mailto:Yourturn2speak.com" className=""> Yourturn2speak.com</a>
          </h6>
        </div>
      </div>
    </>
  )
}

export default FooterContactUsSection