import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { Avatar, Grid, Paper } from "@material-ui/core";

import { fDateTimeSuffix } from "../../utils/formatTime";

import config from "../../config";
import { AuthState } from "../../context/auth";

function Comments() {
    const { debateId } = useParams();
    const queryClient = useQueryClient();
    const [content, setContent] = useState("");
    const { isSignedIn } = AuthState();

    const { data: comments } = useQuery(
        ["comments", debateId],
        async () => {
            const apiurl = `${config.apiUrl}/api/user/debates/${debateId}/comments`;
            const { data } = await axios.get(apiurl);
            return data.data;
        },
        {
            // refetchInterval: 5000,
            // enabled: !isConnected,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        }
    );

    const { mutate, isLoading } = useMutation(
        async () => {
            const { data } = await axios.post(
                `${config.apiUrl}/api/user/comments`,
                {
                    content: content,
                    debate: debateId,
                },
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem(
                            "turn2speak_auth_token"
                        )}`,
                    },
                }
            );
            return data;
        },
        {
            onSuccess: async (d) => {
                await queryClient.refetchQueries(["comments", debateId]);
                setContent("");
            },
        }
    );

    const imgLink =
        "https://images.pexels.com/photos/1681010/pexels-photo-1681010.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260";

    return (
        <>
            {isSignedIn ? (
                <div className="my-3">
                    <label htmlFor="exampleFormControlTextarea1" className="form-label">
                        Comments
                    </label>
                    <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                    ></textarea>
                    <button
                        disabled={isLoading}
                        onClick={mutate}
                        type="button"
                        className="btn btn-secondary mt-3"
                    >
                        Submit
                    </button>
                </div>
            ) : null}

            {comments?.length > 0 &&
                comments.map((row, i) => {
                    let pic = row.user.pic ? config.apiUrl + '/' + row.user.pic : '/';
                    return (
                        <Grid key={i} container wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Avatar alt={row.user.firstName + ' ' + row.user.lastName} src={pic} />
                            </Grid>
                            <Grid justifyContent="left" item xs zeroMinWidth>
                                <Paper style={{ padding: "20px", backgroundColor: "#f8f9fa" }}>
                                    <span style={{ fontSize: "small", fontWeight: "500" }}>
                                        {row?.user?.firstName} {row?.user?.lastName}
                                    </span>
                                    <p style={{ textAlign: "left" }}>{row.content}</p>
                                </Paper>
                                <span
                                    style={{ color: "gray", fontSize: "small", fontWeight: "500" }}
                                >
                                    {fDateTimeSuffix(row.createdAt)}
                                </span>
                            </Grid>
                        </Grid>
                    )
                })
            }
        </>
    );
}

export default Comments;
