import React from "react";
import { AuthState } from "../../context/auth";

import DebateHistoy from "../../components/Profile/DebateHistoy";
import UserDetails from "../../components/Profile/UserDetails";
import Membership from "../../components/Profile/Membership";

function Profile() {
    const { isLoading } = AuthState();
    console.log("Profile", !isLoading);

    return (
        <div>
            <section className="bannerSec innerBanner profil-banner">
                <div
                    className="box-position"
                    style={{ background: "url(/images/profile-banner.jpg)" }}
                ></div>
                <div className="bannerTxt">
                    <h1>Profile</h1>
                </div>
            </section>
            {isLoading ? null : (
                <section className="updatePrflSec">
                    <div className="container">
                        <div className="container">
                            <UserDetails />
                            <Membership />
                            <DebateHistoy />
                        </div>
                    </div>
                </section>
            )}
        </div>
    );
}

export default React.memo(Profile);
