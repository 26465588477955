import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import config from "../../../config";
import { MyAdsContext } from "../../../context/adscontext";

export default function SuccessSection() {

    const [userval]=useState(JSON?.parse(localStorage.getItem("turn2speak_auth_user")))
    const adsdata=useContext(MyAdsContext)
    useEffect(()=>{
      if(userval){
        axios.post(`${config.apiUrl}/api/ads/assignuserid`,{email:userval.email,id:userval._id})
        adsdata.setAdsData([])       
        localStorage.removeItem('data') 
      }

    },[])
    return (
        <>
            <section className="payment-successful-panel">
                <span className="success-icon"><i className="fa-solid fa-thumbs-up"></i></span>
                <h3>Successful</h3>
                <p><strong>Thank You!</strong></p>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
            </section>
        </>
    );
};
