import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link, useParams } from 'react-router-dom'
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material'
import config from '../../config'
import { useQuery } from 'react-query'

function AdsDetails() {
  // const [ad, setAd] = useState()
  // const [isLoading, setIsLoading] = useState(true)
  const { id } = useParams()

  // const getAd = async () => {
  //   axios
  //     .get(`${config.apiUrl}/api/ads/getAd/${id}`)
  //     .then((res) => {
  //       setAd(res.data.resAd)
  //       console.log(res.data.resAd)
  //       setIsLoading(false)
  //     })
  //     .catch((error) => {
  //       console.log('ERROR', error)
  //       setIsLoading(false)
  //     })
  // }


  const { data: ad, isLoading, isError, error } = useQuery(
    ['myads'],
    async () => {
      const url = `${config.apiUrl}/api/ads/getAd/${id}`;
      const { data } = await axios.get(url);
      console.log("data-->",data)
      return data.resAd;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );



  //   mz7ln
  // GXb86A8z
  // console.log('AD STATE', ad);
  const TABLE_HEAD = [
    { id: 'title', label: 'Title', alignRight: false },
    { id: 'images', label: 'Images', alignRight: false },
  ]

  if(isLoading){
    return <p>Loading...</p>
  }
  if(isError){
    return <p>{error.message}</p>
  }


  return (
    <div className='m-2'>

       <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          <Button
            variant="contained"
          
          >
            <Link to="/ads">Create New Ad</Link>{' '}
          </Button>
        </Typography>
      </Stack>
      <Card>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((headCell) => (
                  <TableCell key={headCell.id}>
                    <TableSortLabel>{headCell.label}</TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

    
              <TableBody>
                <TableRow hover tabIndex={-1} role="checkbox">
                
                <TableCell >{ad?.title}</TableCell>
                <div className='viwAddThumb'>
                  {ad?.adsImages.map((img, index) => {
                    return (
                      <TableCell key={index}>
                      
                        <div className='viwAddInrTxt'>
                        <img
                          key={index}
                          src={`${config.apiUrl}/${img.image}`}
                          alt=""
                          height={200}
                          width={200}
                          className="img-fluid"
                        />
                        <h6> {img?.imageOrientation}</h6>
                        </div>
                        
                       
                      </TableCell>
                    )
                  })}
                  </div>
                </TableRow>
              </TableBody>
         
          </Table>
        </TableContainer>
      </Card>
    </Container>
    </div>
   
  )
}

export default AdsDetails
