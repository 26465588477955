import React from 'react'
import { NavLink } from 'react-router-dom'
import { useQueryClient } from "react-query";
import config from "../../config";

const TurnLeft = () => {
    const queryClient = useQueryClient();
    const about = queryClient.getQueryData("About");
    return (
        <>
            <section className="turnLeft">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-4">
                            <img src={config.apiUrl +"/"+ about?.imageUrl} alt={about?.imageUrl} />
                        </div>
                        <div className="col-md-8">
                            <h2>{about?.title}</h2>
                            <p>{about?.description}.</p>
                            <NavLink to="/about" className="btn1">
                                Read More
                            </NavLink>
                        </div>
                        {/* <div className="col-md-3">
                            <div className="vdoSec">
                                <img src="/images/v1.png" alt="" />
                            </div>

                            <div className="vdoSec">
                                <img src="/images/v1.png" alt="" />
                            </div>

                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default TurnLeft