import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Auth } from '../../services/Auth'
import { useForm } from 'react-hook-form'

const Signup = () => {
  const [errMsg, setErrMsg] = useState('')

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const navigate = useNavigate()

  const onSubmit = (data) => {
    console.log('signup data', data)
    setErrMsg(null)

    Auth.registration(data).then(
      (newData) => {
        if (newData.code === 200) {
          console.log('NewData', newData)
          localStorage.setItem('user', JSON.stringify(newData.data))
          // localStorage.setItem('turn2speak_auth_token', newData.data.accessToken)
          // alert("Here")
          return navigate('/pricing')
        }
        console.log(newData.message)
        setErrMsg(newData.message)
      },
      (error) => {
        console.log(error)
        setErrMsg(error.toString())
      },
    )
  }

  return (
    <>
      <section>
        <div className="container">
          <div className="row mt-4">
            <div className="col-md-12">
              <div className="row justify-content-center">
                <div className=" col-lg-12 col-md-12 rgForm">
                  <div className="card o-hidden border-0 shadow-lg my-5">
                    <div className="card-body p-0">
                      <div className="row">
                        <div className="col-lg-4 d-none d-lg-block bg-login-image"></div>
                        <div className="col-lg-8">
                          <div className="p-md-5 p-3">
                            <div className="text-center mb-4">
                              {' '}
                              <img src="images/logo.png" alt="" />
                            </div>
                            <hr />
                            <form
                              className="user row mt-4"
                              onSubmit={handleSubmit(onSubmit)}
                            >
                              <div className="col-md-6 mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  id=""
                                  aria-describedby=""
                                  placeholder="First Name"
                                  //  value={name}
                                  //  onChange={(e) => { setName(e.target.value) }}
                                  {...register('firstName', {
                                    required: true,
                                    maxLength: 10,
                                  })}
                                />
                                {errors.firstName && (
                                  <p style={{ color: 'red' }}>
                                    First Name should be maximum 10 characters
                                  </p>
                                )}
                              </div>
                              <div className="col-md-6 mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  id=""
                                  aria-describedby=""
                                  placeholder="Last Name"
                                  //  value={name}
                                  //  onChange={(e) => { setName(e.target.value) }}
                                  {...register('lastName', {
                                    required: true,
                                    maxLength: 10,
                                  })}
                                />
                                {errors.lastName && (
                                  <p style={{ color: 'red' }}>
                                    Last Name should be maximum 10 characters
                                  </p>
                                )}
                              </div>
                              <div className="col-md-6 mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  id=""
                                  aria-describedby=""
                                  placeholder="Phone Number"
                                  // value={phoneNumber}
                                  // onChange={(e) => { setPhoneNumber(e.target.value) }}
                                  {...register('phone', {
                                    required: true,
                                    maxLength: 10,
                                  })}
                                />
                                {errors.phone && (
                                  <p style={{ color: 'red' }}>
                                    Please check the PhoneNumber or Maximum Name
                                    length is 10
                                  </p>
                                )}
                              </div>
                              <div className="col-md-6 mb-3">
                                <input
                                  type="email"
                                  className="form-control"
                                  id=""
                                  aria-describedby="emailHelp"
                                  placeholder="Email"
                                  // value={email}
                                  // onChange={(e) => { setEmail(e.target.value) }}
                                  {...register('email', {
                                    required: true,
                                    pattern: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                  })}
                                />
                                {errors.email && (
                                  <p style={{ color: 'red' }}>
                                    Please enter the valid email
                                  </p>
                                )}
                              </div>
                              <div className="col-md-12 mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  id=""
                                  aria-describedby=""
                                  placeholder="Username"
                                  // value={userName}
                                  // onChange={(e) => { setUserName(e.target.value) }}
                                  {...register('username', {
                                    required: true,
                                    // maxLength: 10,
                                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                  })}
                                />
                                {errors.username && (
                                  <p style={{ color: 'red' }}>
                                    Minimum eight characters, at least one
                                    uppercase letter, one lowercase letter, one
                                    number and one special character
                                  </p>
                                )}
                              </div>
                              <div className="col-md-12 mb-3">
                                <input
                                  type="password"
                                  className="form-control"
                                  id=""
                                  aria-describedby="emailHelp"
                                  placeholder="Password"
                                  //  value={password}
                                  //  onChange={(e) => { setPassword(e.target.value) }}
                                  {...register('password', {
                                    required: true,
                                    minLength: 6,
                                    // pattern:
                                    //   /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}$/,
                                  })}
                                />
                                {errors.password && (
                                  <p style={{ color: 'red' }}>
                                    Please check the Password, password must be
                                    alpha neumeric
                                  </p>
                                )}
                              </div>

                              <div className="col-md-12">
                                <div className="mb-3 form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="exampleCheck1"
                                    name="checkbox"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleCheck1"
                                  >
                                    Keep me signed in
                                  </label>
                                </div>
                                <div className="col-md-12">
                                  <hr />
                                </div>
                                <p style={{ color: 'red' }}>{errMsg}</p>
                                <div className="col-md-12 btnScn">
                                  <button type="submit" className="btn btn1">
                                    Submit
                                  </button>
                                  <NavLink
                                    to="/login"
                                    className="btn btn1 mx-4"
                                  >
                                    Login
                                  </NavLink>
                                </div>
                              </div>
                            </form>

                            <hr />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Signup
