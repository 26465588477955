const config = {
  apiUrl: String(process.env.REACT_APP_API_BASE_URL),

  roomId: function () {
    return localStorage.getItem("tdroomid");
  },
  setRoomId: function (roomId) {
    localStorage.setItem("tdroomid", roomId);
  },
  meetingUrl: function (roomId) {
    return (
      "https://" +
      process.env.REACT_APP_HMS_TEMPLATE_SUBDOMAIN +
      ".app.100ms.live/preview/" +
      roomId +
      "/broadcaster?skip_preview=true"
    );
  },
  s3ToObjectUrl: function (path) {
    // s3://turntospeak/...
    // https://s3.amazonaws.com/turntospeak/...
    let res = path.replace("s3://", "https://s3.amazonaws.com/");
    console.log(res);
    return res;
  },
  // TOKEN_ENDPOINT="https://prod-in.100ms.live/hmsapi/<your-subdomain>/api/token" # Valid
  hmsApiTokenUrl:
    "https://prod-in2.100ms.live/hmsapi/" +
    process.env.REACT_APP_HMS_TEMPLATE_SUBDOMAIN +
    ".app.100ms.live/api/token",
};

export default config;
