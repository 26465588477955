import HlsPlayer from "../../components/HLSPlayer";
// import HLSView from "./HLSView";

function Stream({liveStreams}) {
    return (
        <div className="">
            <HlsPlayer source={liveStreams[0].playback.url} islive={true} />
        </div>
    );
}

export default Stream;
