import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthState } from "../context/auth";

function Protected({ children }) {
    const { isSignedIn } = AuthState();
    if (!isSignedIn) {
        return <Navigate to="/login" replace />;
    }
  
    return children;
}
export default Protected;
