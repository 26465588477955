import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { useQuery } from 'react-query'
import axios from 'axios'

import config from './config'

// import { AuthState } from "./context/auth";

import Navbar from './components/Navbar/Navbar'
import Footer from './components/Footer/footer'
import Protected from './components/Protected'
import UnAuth from './components/UnAuth'

import Home from './pages/Home/Home'
import About from './pages/About/About'
import Pricing from './pages/Pricing/Pricing'
import Success from './pages/Success/Success'

// import Crime from "./pages/Crime/Crime";
// import Economics from "./pages/Economics/Economics";
// import Politics from "./pages/Politics/Politics";
// import Sports from "./pages/Sports/Sports";
import Topic from './pages/Topic/Topic'
import Watch from './pages/Watch/Watch'

import Testimonials from './pages/Testimonials/Testimonials'
import Contact from './pages/Contact/Contact'
import LiveSession from './pages/Live/Live'
import Login from './pages/Login/Login'
import Signup from './pages/Signup/Signup'
// import ConferenceRoom from "./pages/Room/ConferenceRoom";

import Debates from './pages/Debate/Debates'
import SingleDebate from './pages/Debate/SingleDebate'
import Profile from './pages/Profile'
import Payment from './pages/Payment/Payment'
import Ads from './pages/ads/Ads'
import Termsconditions from './pages/Termsconditions/Termsconditions'
import ViewAds from './pages/ViewAds/ViewAds'
import AdsDetails from './pages/ViewAds/AdDetails'
import Subscriptionpayment from './pages/Payment/Subscriptionpayment'
import TrielProtection from './components/TrielProtection'
import AdsProtection from './components/Adsprotection'

function App() {
  const { data: topics } = useQuery(
    ['allTopics'],
    async () => {
      const url = `${config.apiUrl}/api/admin/getTopics`
      const { data } = await axios.get(url)
      return data.data
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  )

  useQuery(
    ['subscription'],
    async () => {
      const url = config.apiUrl + '/api/subscription/getAll'
      const { data } = await axios.get(url)
      return data.data
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  )

  return (
    <>
      <Navbar />
      <Routes>
        <Route
          path="/"
          exact
          element={
            <TrielProtection>
              <Home />
            </TrielProtection>
          }
        />
        <Route path="/about" exact element={<About />} />
        <Route path="/contact" exact element={<Contact />} />
        <Route path="/testimonials" exact element={<Testimonials />} />
        <Route path="/pricing" exact element={<Pricing />} />
        <Route path="/success" exact element={<Success />} />
        <Route path="/live-session" exact element={<LiveSession />} />

        {topics &&
          topics
            .filter((t) => t.showOnMenu)
            .map((t) => (
              <Route
                key={t._id}
                path={`/topic/${t._id}`}
                element={<Topic topic={t} />}
              />
            ))}

        {/* {topics && topics.filter((t) => t.showOnMenu).map((t) => (
          <Route key={t._id} path={`/topic/${t._id}`} element={<Politics topic={t} />} />
        ))}
        <Route path="/crime" element={<Crime />} />
        <Route path="/economics" element={<Economics />} />
        <Route path="/sports" element={<Sports />} /> */}

        <Route
          path="/login"
          exact
          element={
            <UnAuth>
              <Login />
            </UnAuth>
          }
        />
        <Route
          path="/signup"
          exact
          element={
            <UnAuth>
              <Signup />
            </UnAuth>
          }
        />
        <Route
          path="/profile"
          exact
          element={
            <Protected>
              <TrielProtection>
                <AdsProtection>
                  <Profile />
                </AdsProtection>
              </TrielProtection>
            </Protected>
          }
        />

        <Route path="/debates" exact element={<Debates />} />
        <Route
          path="/debates/:debateId"
          exact
          element={
            <TrielProtection>
              <AdsProtection>
                <SingleDebate />
              </AdsProtection>
            </TrielProtection>
          }
        />

        <Route path="/watch/:watchid" exact element={<Watch />} />
        <Route path="/termsconditions" exact element={<Termsconditions />} />
        <Route path="/payment/:price" exact element={<Payment />} />
        <Route path="/ads" exact element={<Ads />} />
        <Route path="/success" exact element={<Success />} />
        {/* <Route path="/home" exact element={<ConferenceRoom />} /> */}
        <Route
          path="viewads/:id"
          exact
          element={
            <Protected>
              <TrielProtection>
                <ViewAds />
              </TrielProtection>
            </Protected>
          }
        />
        <Route
          path="viewads/adsDetails/:id"
          exact
          element={
            <Protected>
              <TrielProtection>
                <AdsDetails />
              </TrielProtection>
            </Protected>
          }
        />
        <Route
          path="/Subscriptionpayment/:price/:duration"
          exact
          element={
            <AdsProtection>
              <Subscriptionpayment />
            </AdsProtection>
          }
        />
      </Routes>
      <Footer />
    </>
  )
}

export default App
