import React, { useEffect, useState, memo } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useQueryClient, useMutation } from "react-query";
import config from "../../config";

function UserDetails() {
    const queryClient = useQueryClient();
    const authUser = queryClient.getQueryData("myProfile");

    const { mutate, isLoading } = useMutation(
        async (payload) => {
            const url = `${config.apiUrl}/api/user/myProfile`;
            const headers = {
                headers: {
                    authorization: `Bearer ${localStorage.getItem(
                        "turn2speak_auth_token"
                    )}`,
                },
            };
            payload.userId = authUser._id;
            // console.log("payload", payload);
            const { data } = await axios.post(url, payload, headers);
            return data;
        },
        {
            onSuccess: async (d) => {
                await queryClient.invalidateQueries("myProfile");
            },
        }
    );

    // form input intial values
    const initialValues = {
        firstName: "",
        lastName: "",
        phone: "",
        bio: "",
    };
    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .min(2, "Too Short!")
            .max(32, "Too Long!")
            .required("First name is required"),
        lastName: Yup.string()
            .min(2, "Too Short!")
            .max(32, "Too Long!")
            .required("Last name is required"),
        phone: Yup.string()
            .required("Phone number is required")
            .matches(new RegExp("[0-9]{10}"), "Invalid phone number")
            .max(10, "Number Should not be more than 10 character"),
        bio: Yup.string().min(5, "Too Short!").max(500, "Too Long!"),
    });
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, action) => {
            mutate(values);
        },
    });

    const [avatar, setAvatar] = useState("/images/edite-prfile.jpg");
    const [isImgLoading, setImgIsLoading] = useState(false);
    const [selectedImage, setselectedImage] = useState(true);
    const onChangeImages = (event) => {
        setselectedImage(false);
        let input = event.target;
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                setAvatar(reader.result);
            };
            reader.readAsDataURL(input.files[0]);
        }
    };
    async function handleUpload(e) {
        e.preventDefault();
        try {
            setselectedImage(true);
            setImgIsLoading(true);
            const url = `${config.apiUrl}/api/user/profile/upload`;
            const headers = {
                headers: {
                    authorization: `Bearer ${localStorage.getItem(
                        "turn2speak_auth_token"
                    )}`,
                },
            };
            const payload = new FormData(e.target);
            await axios.post(url, payload, headers);
            await queryClient.invalidateQueries("myProfile");
            setImgIsLoading(false);
        } catch (err) {
            setselectedImage(true);
            setImgIsLoading(false);
        }
    }

    useEffect(() => {
        if (authUser) {
            if (authUser.pic) {
                setAvatar(config.apiUrl + "/" + authUser.pic);
            }
            formik.setFieldValue(
                "firstName",
                authUser?.firstName ? authUser.firstName : ""
            );
            formik.setFieldValue(
                "lastName",
                authUser?.lastName ? authUser.lastName : ""
            );
            formik.setFieldValue("phone", authUser?.phone ? authUser.phone : "");
            formik.setFieldValue("bio", authUser?.bio ? authUser.bio : "");
        }
    }, []);

    return (
        <div className="updateWrap">
            <div className="row align-items-center p-0 m-0">
                <div className="col-md-4 p-0">
                    <form
                        encType="multipart/form-data"
                        method="post"
                        onSubmit={handleUpload}
                    >
                        <div className="profileImg">
                            <span>
                                <img src={avatar} alt="" />
                                <i className="fa-solid fa-pen"></i>
                                <input
                                    type="file"
                                    name="file"
                                    onChange={onChangeImages}
                                    className="file-upload-input"
                                    accept="image/*"
                                />
                                {authUser?._id ? (
                                    <input type="hidden" name="userId" value={authUser._id} />
                                ) : null}
                            </span>
                            <button
                                disabled={selectedImage}
                                type="submit"
                                className="btn btn-warning btn-upload mt-5"
                            >
                                {isImgLoading ? (
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                ) : (
                                    "Upload"
                                )}
                            </button>
                        </div>
                    </form>
                </div>
                <div className="col-md-8">
                    <div className="col-12 pb-4">
                        <h2>Update profile information </h2>
                    </div>
                    <form className="col-12 pb-4" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <label className="form-label">Username</label>
                                <div className="input-group mb-3">{authUser?.username}</div>
                            </div>

                            <div className="col-md-6">
                                <label className="form-label">Email ID</label>
                                <div className="input-group mb-3">{authUser?.email}</div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label className="form-label">First Name</label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        placeholder=""
                                        name="firstName"
                                        autoComplete="off"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.firstName}
                                        className="form-control"
                                        aria-label=""
                                        aria-describedby=""
                                    />
                                </div>
                                {formik.touched.firstName && formik.errors.firstName ? (
                                    <p className="text-danger small">
                                        <strong>{formik.errors.firstName}</strong>
                                    </p>
                                ) : null}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="form-label">Last Name</label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        placeholder=""
                                        name="lastName"
                                        autoComplete="off"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.lastName}
                                        className="form-control"
                                        aria-label=""
                                        aria-describedby=""
                                    />
                                </div>
                                {formik.touched.lastName && formik.errors.lastName ? (
                                    <p className="text-danger small">
                                        <strong>{formik.errors.lastName}</strong>
                                    </p>
                                ) : null}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="form-label">Phone number</label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        placeholder=""
                                        name="phone"
                                        autoComplete="off"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.phone}
                                        className="form-control"
                                        aria-label=""
                                        aria-describedby=""
                                    />
                                </div>
                                {formik.touched.phone && formik.errors.phone ? (
                                    <p className="text-danger small">
                                        <strong>{formik.errors.phone}</strong>
                                    </p>
                                ) : null}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <label className="form-label">
                                    Bio info in 500 words ({formik.values.bio.length})
                                </label>
                                <div className="input-group">
                                    <textarea
                                        name="bio"
                                        className="form-control"
                                        rows="6"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.bio}
                                    ></textarea>
                                </div>
                                <br />
                                {formik.touched.bio && formik.errors.bio ? (
                                    <p className="text-danger small">
                                        <strong>{formik.errors.bio}</strong>
                                    </p>
                                ) : null}
                            </div>

                            <div className="col-md-12">
                                <button disabled={isLoading} className=" btn1 btn mt-3">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default memo(UserDetails);
