import React from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { NavLink } from "react-router-dom";
import config from "../../config";
import Video from "../../pages/Topic/TopicContentSection/TopicVideos/Video";

const DebateVideoList = () => {

    const { data: latestVideo } = useQuery(
        ["latestVideo"],
        async () => {
            const url = `${config.apiUrl}/api/videos?limit=2`;
            const { data } = await axios.get(url);
            return data.data;
        },
        {
            // enabled: !!isAuth,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        }
    );

    console.log(latestVideo);

    return (
        <>
            {latestVideo &&
                latestVideo.map((row, i) => (
                    <div key={i} className="vdoLive">
                        <NavLink
                            to={`/watch/${row._id}`}
                            className={"nav-link"}
                            aria-current="page"
                        >
                            <Video src={row.path} debate={row.debate} />
                        </NavLink>
                    </div>
                ))
            }
        </>
    )
}

export default DebateVideoList