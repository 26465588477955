import React from "react";
import { NavLink } from "react-router-dom";

const FooterLogoWithCaptionSection = () => {
  return (
    <>
      <div className="col-md-6 logoSec">
        <NavLink to="/" className="footerLogo">
          <img src="/images/footer-logo.png" alt="" />
        </NavLink>

        <p>
          YourTurn2Speak is designed to efficiently organize and facilitate
          engaging debates. It offers a comprehensive and user-friendly platform
          for organizing debates, fostering meaningful conversations, and
          empowering individuals to express their thoughts effectively
        </p>
      </div>
    </>
  );
};

export default FooterLogoWithCaptionSection;
