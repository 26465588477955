import React from "react";


import TopicArticle from "./TopicArticle/TopicArticle";
import TopicVideos from "./TopicVideos/TopicVideos";

const TopicContentSection = ({ topic }) => {
  return (
    <>
      <section className="turnLeft my-5">
        <div className="container">

          <TopicArticle topic={topic} />

          <hr />

          <TopicVideos topic={topic} />

        </div>
      </section>
    </>
  );
};

export default TopicContentSection;
