import React from "react";

export default function BannerSection() {
    return (
        <>
            <section className="bannerSec innerBanner">
                <div
                    className="box-position"
                    style={{ background: 'url("images/innerBanner.jpg")' }}
                ></div>
                <div className="bannerTxt">
                    <h1>Success</h1>
                </div>
            </section>
        </>
    );
}
