import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { HMSRoomProvider } from '@100mslive/react-sdk'
import { QueryClient, QueryClientProvider } from 'react-query'

import { AuthContextProvider } from './context/auth'
import App from './App'
import './index.css'
import CustomProvider from './context/adscontext'
import CustomTrialEndProvider from './context/trialend'
import CustomAdsProtection, { MyAdsCheck } from './context/adsProtection'

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <AuthContextProvider>
  // <React.StrictMode>
  <HMSRoomProvider>
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <CustomProvider>
          <CustomTrialEndProvider>
            <CustomAdsProtection>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </CustomAdsProtection>
          </CustomTrialEndProvider>
        </CustomProvider>
      </AuthContextProvider>
    </QueryClientProvider>
  </HMSRoomProvider>,
  // </React.StrictMode>
  // </AuthContextProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
