import React from 'react'
import TestimonialsBannerSection from './TestimonialsBannerSection/TestimonialsBannerSection'
import TestimonialsSection from './TestimonialsSection/TestimonialsSection'

const Testimonials = () => {
  return (
    <>
        <TestimonialsBannerSection/>
        <TestimonialsSection/>
    </>
  )
}

export default Testimonials