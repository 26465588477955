import axios from "axios";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import config from "../../config";
import { MyAdsContext } from "../../context/adscontext";

export default function Ads() {
  const nav = useNavigate();
  const [colortoggle, setcolortoggle] = useState(false);
  const adsdata = useContext(MyAdsContext);

  const [token] = useState(localStorage.getItem("turn2speak_auth_token"));

  console.log("adscontext", adsdata);
  ///Submit Form

  const submithandler = (val) => {
    const unique = allpkg.filter((ele) => {
      return Number(ele.price) === Number(val.package);
    });
    axios
      .post(config.apiUrl + "/api/ads/checkmail", {
        email: String(val.email).toLowerCase(),
      })
      .then((res) => {
        if (!!!token && res.data.user) {
          alert(res.data?.msg);
          nav("/login");
        } else {
          console.log("Unqu", unique);
          let r = (Math.random() + 1).toString(36).substring(7);
          console.log("random", r);
          const extractimage = adsImages.map((ele) => ele.image);

          const extractobj = adsImages.map((each) => {
            return { link: each.link, imageOrientation: each.imageOrientation };
          });
          const allData = {
            ...val,
            extractimage,
            extractobj,
            adsPackageId: unique[0]._id,
            duration: unique[0].duration,
            username: `Yourturn2speak${r}@123`,
            role: "64ba61a26d56e3436dbe9cf8",
          };
          adsdata.setAdsData(allData);

          localStorage.setItem(
            "data",
            JSON.stringify({
              firstName: allData.firstName,
              lastName: allData.lastName,
            })
          );
          nav(`/payment/${unique[0].price}`);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };
  //all pkg details in endpoint
  const [allpkg, setpkg] = useState([]);

  const getPackages = () => {
    axios.get(`${config.apiUrl}/api/adPackage/getPackages`).then((res) => {
      console.log("res", res.data.adPackages);
      setpkg(res.data.adPackages);
    });
  };

  //base64 conversion
  const convertBase64 = (file, index, x, y) => {
    console.log("File--->", file, index, x, y);
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = function () {
        const img = new Image();
        img.src = fileReader.result;
        img.onload = function () {
          console.log("Height", this.height, "Width", this.width);
          if (this.height != parseInt(y) || this.width != parseInt(x)) {
            document.getElementsByClassName("img_size_err")[
              index
            ].innerText = `Your Image is  ${this.height} X ${this.width}`;
            document.getElementsByClassName("img_err")[
              index
            ].innerText = `Size should  ${x} X ${y}`;
            setcolortoggle(true);
            document.getElementsByClassName("img_err")[index].style.color =
              "red !important";

            document.getElementsByClassName("img_size_err")[index].style.color =
              "red !important";

            return false;
          } else {
            resolve(fileReader.result);
            document.getElementsByClassName("img_err")[index].innerText = "";
            document.getElementsByClassName("img_size_err")[index].innerText =
              "";

            setcolortoggle(false);
            return true;
          }
        };
      };
      fileReader.onerror = (err) => {
        reject(err);
      };
    });
  };
  //Image validation
  const valid_ext = ["jpeg", "jpg", "JPEG", "JPG", "PNG", "png"];

  const validator = async (value, index) => {
    let msz5 = null;
    console.log("My value", value);
    if (value == undefined || value == "null" || value == null) {
      document.getElementsByClassName("img_err")[index].innerText =
        "Please Choose Image";
      setcolortoggle(true);

      msz5 = false;
      return msz5;
    } else {
      //Image extension Check
      const img_ext = value?.name.lastIndexOf(".") + 1;
      const slicer = value?.name.substring(img_ext);
      const checking_ext = valid_ext.includes(slicer);
      //Image Size validation
      const image_size = value?.size / 1024; //converting bytes to kb
      const image_valid_size = 2048;

      console.log("My check for ext", checking_ext);
      if (!checking_ext) {
        document.getElementsByClassName("img_err")[index].innerText =
          "File Type supported:JPEG,JPG,PNG";
        setcolortoggle(true);
        msz5 = false;

        return msz5;
      } else if (image_size > image_valid_size) {
        console.log("Your image size in mb :", (image_size / 1024).toFixed(2));
        document.getElementsByClassName("img_err")[index].innerText =
          "Image should less than 2mb";
        setcolortoggle(true);
        msz5 = false;

        return msz5;
      } else {
        document.getElementsByClassName("img_err")[index].innerText = "";
        setcolortoggle(false);

        msz5 = true;

        return msz5;
      }
    }
  };

  //5th approach
  const [adsImages, setList] = useState([
    { image: null, link: "", imageOrientation: "" },
  ]);
  //Handle image and link data
  const handledata = async (e, id) => {
    let x;
    let y;
    let base;
    if ("image" == e.target.name) {
      console.log("Choose", adsImages);
      if (e.target.files[0]) {
        if (
          adsImages[e.target.dataset.id].imageOrientation == "" ||
          adsImages[e.target.dataset.id].imageOrientation == undefined
        ) {
          // alert("Select orientation")
          document.getElementsByClassName("imageOrientation")[
            e.target.dataset.id
          ].innerText = "Select Orientation";

          // return false
        } else {
          console.log("Choose", adsImages);
          // alert('in else')
          const valiate = await validator(
            e.target.files[0],
            e.target.dataset.id
          );

          document.getElementsByClassName("imageOrientation")[
            e.target.dataset.id
          ].innerText = "";

          if (adsImages[e.target.dataset.id].imageOrientation == "vertical") {
            x = 131;
            y = 1080;
            // alert("ver")
          } else if (
            adsImages[e.target.dataset.id].imageOrientation == "horizontal"
          ) {
            x = 1920;
            y = 170;
            // alert('hori')
          }
          console.log("xy", x, y);
          base = await convertBase64(
            e.target.files[0],
            e.target.dataset.id,
            x,
            y
          );

          // console.log('Base', base)
          if (base && valiate) {
            let task = [...adsImages];
            task[e.target.dataset.id][e.target.name] = e.target.files[0];
            setList(task);
          } else {
          }
        }
      }
    } else if ("link" == e.target.name) {
      let task = [...adsImages];
      task[e.target.dataset.id][e.target.name] = e.target.value;
      setList(task);
    } else if ("imageOrientation" == e.target.name) {
      console.log(e.target.value, "---");
      const orie = [];
      if (e.target.value.includes("vertical")) {
        // setOrientationError(()=>'Size should 1000 X 200')
        orie.push("Size should 131  X 1080");
        // alert('v')
      } else if (e.target.value == "horizontal") {
        // alert("j")
        orie.push("Size should 1920 X 170");
        // setOrientationError(()=>'Size should 200 X 1000')
      }

      document.getElementsByClassName("img_err")[
        e.target.dataset.id
      ].innerText = `${orie}`;
      let imageO = [...adsImages];
      imageO[e.target.dataset.id][e.target.name] = e.target.value;
      imageO[e.target.dataset.id].image = null;
      setList(imageO);
    }
  };
  const addrow = () => {
    const o = { image: null, link: "", imageOrientation: "" };
    const chnge = [o, ...adsImages];
    console.log("change", chnge);
    setList((prev) => [...prev, o]);
    console.log("state change", adsImages);
  };

  const filedvalidation = Yup.object().shape({
    firstName: Yup.string().required("First name required"),
    lastName: Yup.string().required("last name required"),
    email: Yup.string().email().required("Email required"),
    phone: Yup.number()
      .required("phone required")
      .min(10, "Minimum 10 digits allowed"),
    package: Yup.string().required("choose one of the package"),
    title: Yup.string().required("Enter a title"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      package: "",
      title: "",
    },
    validationSchema: filedvalidation,
    onSubmit: (values, action) => {
      const validation_err = [];
      const urlRegex =
        /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/;

      adsImages.map((ele, index) => {
        if (!ele.image) {
          validation_err.push("Choose File");
          document.getElementsByClassName("img_err")[index].innerText =
            "Choose file";
          setcolortoggle(true);
        } else {
          document.getElementsByClassName("img_err")[index].innerText = "";
          // document.getElementsByClassName('img_err')[index].style.color="green"
        }

        if (!ele.link) {
          validation_err.push("Enter a Link");
          document.getElementsByClassName("tick")[index].innerText =
            "Enter Link";
        } else {
          document.getElementsByClassName("tick")[index].innerText = "";
        }
        if (!ele.link.match(urlRegex)) {
          validation_err.push("Enter Valid  Link");
          document.getElementsByClassName("tick")[index].innerText =
            "Enter Valid  Link";
        } else {
          document.getElementsByClassName("tick")[index].innerText = "";
        }
        if (!ele.imageOrientation) {
          validation_err.push("Select orientation");
          document.getElementsByClassName("imageOrientation")[index].innerText =
            "Select Orientation";
        } else {
          document.getElementsByClassName("imageOrientation")[index].innerText =
            "";
        }
      });
      console.log("Validate", validation_err);
      if (validation_err.length == 0) {
        submithandler(values);
      }

      // action.resetForm()
    },
  });

  const remove = (e, val) => {
    e.preventDefault();
    const del = adsImages.filter((ele, id) => {
      console.log("del", ele);
      return id != val;
    });
    console.log("Finaly reqult", del);
    setList(del);
  };

  //if logged in user value will set automaically
  useEffect(() => {
    getPackages();
    const userval = window.localStorage.getItem("turn2speak_auth_user") ? JSON.parse(localStorage.getItem("turn2speak_auth_user")) : {}
    console.log("userval?.email", userval?.email);
    if (userval && userval.hasOwnProperty("email")) {
      formik.setFieldValue("firstName", userval?.firstName ? userval?.firstName : "");
      formik.setFieldValue("lastName", userval?.lastName ? userval?.lastName : "");
      formik.setFieldValue("phone", userval?.phone ? userval?.phone : "");
      formik.setFieldValue("email", userval?.email ? userval.email : "");
    }
  }, []);

  return (
    <section>
      <div className="container">
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="row justify-content-center">
              <div className=" col-lg-12 col-md-12 rgForm">
                <div className="card o-hidden border-0 shadow-lg my-5">
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-4 d-none d-lg-block">
                        <div className="bg-login-image"></div>
                      </div>
                      <div className="col-lg-8">
                        <div className="p-md-5 p-3">
                          <div className="text-center mb-4">
                            {" "}
                            <img src="images/logo.png" alt="" />
                          </div>
                          <hr />
                          <form
                            className="user row mt-4"
                            onSubmit={formik.handleSubmit}
                            onChange={handledata}
                            encType="multipart/form-data"
                          >
                            <div className="col-md-6 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id=""
                                aria-describedby=""
                                placeholder="First Name"
                                name="firstName"
                                // onChange={allInput}
                                // value={validat.firstName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.firstName}
                              />

                              {formik.touched.firstName && (
                                <p className="text-danger">
                                  {formik.errors.firstName}
                                </p>
                              )}
                            </div>
                            <div className="col-md-6 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id=""
                                aria-describedby=""
                                placeholder="Last Name"
                                name="lastName"
                                // onChange={allInput}
                                // value={validat.lastName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.lastName}
                              />
                              {formik.touched.lastName && (
                                <p className="text-danger">
                                  {formik.errors.lastName}
                                </p>
                              )}
                            </div>
                            <div className="col-md-6 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id=""
                                aria-describedby=""
                                placeholder="Phone Number"
                                name="phone"
                                // onChange={allInput}
                                // value={validat.phone}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.phone}
                              />
                              {formik.touched.phone && (
                                <p className="text-danger">
                                  {formik.errors.phone}
                                </p>
                              )}
                            </div>
                            <div className="col-md-6 mb-3">
                              <input
                                type="email"
                                className="form-control"
                                id=""
                                aria-describedby="emailHelp"
                                placeholder="Email"
                                name="email"
                                // onChange={allInput}
                                // value={validat.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                              />
                              {formik.touched.email && (
                                <p className="text-danger">
                                  {formik.errors.email}
                                </p>
                              )}
                            </div>
                            <div className="col-md-12 mb-3">
                              <select
                                className="form-select"
                                // value={validat.package}
                                name="package"
                                // onChange={allInput}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.package}
                              >
                                <option value="">--select package---</option>
                                {allpkg.map((ele) => {
                                  return (
                                    <option key={ele._id} value={ele.price}>
                                      {ele.duration}sec - {ele.price}$
                                    </option>
                                  );
                                })}
                              </select>
                              {formik.touched.package && (
                                <p className="text-danger">
                                  {formik.errors.package}
                                </p>
                              )}
                            </div>
                            <div className="col-md-6 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id=""
                                aria-describedby=""
                                placeholder="Enter Title"
                                name="title"
                                // onChange={allInput}
                                // value={validat.phone}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.title}
                              />
                              {formik.touched.title && (
                                <p className="text-danger">
                                  {formik.errors.title}
                                </p>
                              )}
                            </div>

                            {/* 5th approach */}
                            <div className="col-md-12 mb-3">
                              <div className="row order-text">
                                {adsImages.map((ele, idx) => {
                                  let projectName = `image-${idx}`;
                                  return (
                                    <div className="col-md-6 mb-3" key={idx}>
                                      <div className="d-flex gap-2 justify-content-center w-100">
                                        <div className="d-flex flex-column gap-2 w-100">
                                          <div className="d-flex flex-row gap-2 w-100">
                                            <label
                                              htmlFor={projectName}
                                              className="shadow-lg mb-0 bg-body rounded dataimg d-flex align-items-center justify-content-center"
                                            >
                                              {ele.image ? (
                                                <img
                                                  className="img-fluid"
                                                  alt=""
                                                  src={
                                                    ele.image
                                                      ? URL.createObjectURL(
                                                        ele.image
                                                      )
                                                      : ""
                                                  }
                                                />
                                              ) : (
                                                <i className="fas fa-image"></i>
                                              )}

                                              <input
                                                type="file"
                                                className="form-control"
                                                data-id={idx}
                                                id={projectName}
                                                onChange={(e) =>
                                                  handledata(e, idx)
                                                }
                                                aria-describedby="emailHelp"
                                                name={`image`}
                                                accept="image/*"
                                              />
                                            </label>
                                            <br />

                                            <div>
                                              <input
                                                type="text"
                                                className="form-control"
                                                data-id={idx}
                                                id={projectName}
                                                onChange={(e) =>
                                                  handledata(e, idx)
                                                }
                                                // onClick={getLinkValidation}
                                                aria-describedby=""
                                                placeholder="Enter link"
                                                name="link"
                                              />
                                              <p
                                                className="text-danger tick m-0"
                                                id={idx}
                                              ></p>
                                              <select
                                                className="form-select mt-2"
                                                name="imageOrientation"
                                                data-id={idx}
                                                id={projectName}
                                                onChange={(e) =>
                                                  handledata(e, idx)
                                                }
                                              >
                                                <option>
                                                  Choose the orientation
                                                </option>
                                                <option value="vertical">
                                                  Vertical
                                                </option>
                                                <option value="horizontal">
                                                  Horizontal
                                                </option>
                                              </select>
                                              <p
                                                className="text-danger imageOrientation m-0"
                                                id={idx}
                                              ></p>
                                            </div>
                                          </div>
                                          <div>
                                            <p
                                              className={`text-secondary  img_size_err ${colortoggle
                                                ? "text-danger"
                                                : "text-secondary"
                                                }`}
                                            ></p>
                                            <p
                                              className={`text-secondary  img_err ${colortoggle
                                                ? "text-danger"
                                                : "text-secondary"
                                                }`}
                                            ></p>
                                          </div>
                                        </div>

                                        {idx == 0 ? (
                                          <button
                                            onClick={() => addrow()}
                                            type="button"
                                            className={`${adsImages.length > 4
                                              ? "btn btn-secondary text-center disabled"
                                              : "btn btn-success text-center"
                                              }`}
                                          >
                                            {/*style={{width: "100px"}}*/}
                                            <i
                                              className="fa fa-plus-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                        ) : (
                                          <button
                                            className="btn btn-danger"
                                            onClick={(e) => remove(e, idx)}
                                          >
                                            <i
                                              className="fas fa-trash-alt"
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="col-md-12">
                                <hr />
                              </div>
                              <div className="col-md-12 btnScn">
                                <button type="submit" className="btn btn1">
                                  Pay
                                </button>
                              </div>
                            </div>
                          </form>

                          <hr />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
