import React from "react";

function Membership() {


    return (

        <div className="memberInformtn">
            <h2>Membership information</h2>
            <button className="cacelMember btn1">Cancel Membership</button>
            <div className="innerSec">
                <ul className="list-group ">
                    <li
                        className="list-group-item d-flex justify-content-between
                align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <h6>abcd@gmail.com</h6>
                        </div>

                        <span>
                            <a href="#">Change account email</a>
                        </span>
                    </li>

                    <li
                        className="list-group-item d-flex justify-content-between
                align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">Password: **** ****</div>
                        </div>

                        <span>
                            <a href="#">Change password </a>{" "}
                        </span>
                    </li>

                    <li
                        className="list-group-item d-flex justify-content-between
                align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">Phone : 98745 63210</div>
                        </div>

                        <span>
                            <a href="#">Change phone number</a>
                        </span>
                    </li>
                    <hr />
                    <li
                        className="list-group-item d-flex justify-content-between
                align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">
                                <img src="images/visa.jpg" alt="" /> .... .... ....
                            </div>
                        </div>
                        <span>
                            <a href="#">Manage payment info </a>
                        </span>{" "}
                        /
                        <br />
                        <span>
                            {" "}
                            <a href="#"> Billing details </a>
                        </span>
                    </li>
                </ul>
            </div>

            <div className="planDetails">
                <h2>Plan Details</h2>
                <ul className="list-group ">
                    <li
                        className="list-group-item d-flex justify-content-between
                align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <h6>Gold Plan</h6>
                        </div>

                        <span>
                            <a href="#">Change plan</a>{" "}
                        </span>
                    </li>
                </ul>
            </div>
        </div>

    );
}

export default Membership;
