import React from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { Link } from "react-router-dom";

import config from "../../../../config";

// import Video from "./Video";

const  TopicVideos = ({ topic }) => {
  const { data: topicvideos } = useQuery(
    ["topicvideos", topic._id],
    async () => {
      const url = `${config.apiUrl}/api/videos?topic=${topic._id}`;
      const { data } = await axios.get(url);
      return data.data;
    },
    {
      // enabled: !!isAuth,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  console.log(topicvideos);

  const dDate = (debate) => {
    // const startTime = new Date(debate?.startDate).getHours ()
    const endTime =
      new Date(debate?.startDate).getTime() + debate?.duration * 60000;
    const endDate = new Date(endTime);

    var shours = new Date(debate?.startDate).getHours();
    var sminutes = new Date(debate?.startDate).getMinutes();
    var ehours = new Date(endDate).getHours();
    var eminutes = new Date(endDate).getMinutes();

    // Check whether AM or PM
    var snewformat = shours >= 12 ? "PM" : "AM";
    var enewformat = ehours >= 12 ? "PM" : "AM";

    // Find current hour in AM-PM Format
    shours = shours % 12;
    ehours = ehours % 12;

    // To display "0" as "12"
    shours = shours ? shours : 12;
    sminutes = sminutes < 10 ? "0" + sminutes : sminutes;
    ehours = ehours ? ehours : 12;
    eminutes = eminutes < 10 ? "0" + eminutes : eminutes;

    const startTime = shours + ":" + sminutes + " " + snewformat;
    const endT = ehours + ":" + eminutes + " " + enewformat;

    return { startTime, endT, endTime };
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className="debateSlider">
      <div className="row">
        {topicvideos &&
          topicvideos.map((row, i) => {
            console.log("row",row)
            const poster = row.debate?.imageUrl
              ? config.apiUrl + "/" + row.debate.imageUrl
              : "";
            return (
              // <div key={i} className="col-md-4">
              //   <div className="vdoSec">
              //     <NavLink
              //       to={`/watch/${row._id}`}
              //       className={"nav-link"}
              //       aria-current="page"
              //     >
              //       <Video src={row.path} debate={row.debate} />
              //       <h5>
              //         {row.debate?.title}
              //       </h5>
              //     </NavLink>
              //   </div>
              // </div>

              <div key={i} className="col-md-4 col-sm-6 col-12">
                <div className="dbateSldr mb-3">
                  <div className="imgSec">
                    <Link
                      to={`/watch/${row._id}`}                      
                      aria-current="page"
                    >
                      <span>
                        {new Date(row.debate?.createdAt).getDate()},{" "}
                        {months[new Date(row.debate?.createdAt).getMonth()]},{" "}
                        {new Date(row.debate?.createdAt).getFullYear()} / {dDate(row.debate).startTime}
                      </span>
                      <img src={poster} alt="poster" />
                    </Link>
                  </div>

                  <div className="mt-3">
                    <Link
                      to={`/watch/${row._id}`}
                      aria-current="page"
                    >
                      <p>{row.debate?.description} </p>
                      <h5>{row.debate?.title} </h5>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TopicVideos;
