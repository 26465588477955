import { CreditCard, Ach, PaymentForm } from "react-square-web-payments-sdk";
import axios from "axios";
import config from "../../config";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Spinner from "../../components/Spinner/spinner";
import { Box, CircularProgress } from "@mui/material";
import { MyAdsContext } from "../../context/adscontext";

export default function Payment() {
  // const [loader, setLoader] = useState(false);
  const adsdata = useContext(MyAdsContext);
  const { price } = useParams();
  const nav = useNavigate();
  const [userval] = useState(
    JSON.parse(localStorage.getItem("turn2speak_auth_user"))
  );
  const allData = JSON.parse(localStorage.getItem("data"));
  // const [details, setDetails] = useState(allData.allData)
  const [togglerpay, settogglerPay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log("adsdata == ", adsdata.adsData);
    if (!adsdata?.adsData?.email) {
      nav("/");
    }
    console.log("Website Load");
  }, [adsdata.adsData, nav]);

  useEffect(() => {
    if (adsdata?.adsData?.email && userval === null) {
      axios.post(config.apiUrl + "/api/ads/checkmail", {
        email: adsdata?.adsData?.email,
      }).then((res) => {
        // console.log("checkmail", res.data);
        if (res.data.user) {
          settogglerPay(false);
          alert(res.data?.msg);
          nav("/login");
          return false;
        } else {
          settogglerPay(true);
        }
      }).catch((err) => {
        settogglerPay(false);
        console.log("Error", err);
      });
    }
  }, [adsdata?.adsData, nav, userval]);

  ///Send Data
  const sendData = () => {
    setIsLoading(true);
    const form = new FormData();
    form.append("firstName", adsdata.adsData.firstName);
    form.append("lastName", adsdata.adsData.lastName);
    form.append("phone", adsdata.adsData.phone);
    form.append("email", adsdata.adsData.email);
    form.append("package", adsdata.adsData.package);
    form.append("adsPackageId", adsdata.adsData.adsPackageId);
    form.append("duration", adsdata.adsData.duration);
    form.append("role", adsdata.adsData.role);
    form.append("title", adsdata.adsData.title);
    form.append("username", adsdata.adsData.username);
    form.append("extractobj", JSON.stringify(adsdata.adsData.extractobj));

    for (let i = 0; i < adsdata.adsData.extractimage.length; i++) {
      form.append("adsimage", adsdata.adsData.extractimage[i]);
    }
    // Object.entries(adsdata.adsData.extractobj).forEach(([key, value]) => {
    //   form.append(key, value)
    // })

    for (let key in form) {
      console.log("Keyyyy", key);
    }

    axios
      .post(`${config.apiUrl}/api/ads/createAds`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log("======>", res.data);
        setIsLoading(false);
        alert(res.data.msg);
        if (res.data.msg === "User and ads created successfully") {
          setIsLoading(true);
          axios
            .post(`${config.apiUrl}/api/mail/mail`, {
              email: res?.data?.user?.email,
              password: res?.data?.createdPassword,
            })
            .then((res) => {
              console.log(res.data, "mail setting");
              setIsLoading(false);
              alert(res.data.message);
              nav("/success");
              setTimeout(() => {
                localStorage.removeItem("data");
              }, 4000);
            })
            .catch((e) => {
              setIsLoading(false);
              console.log(e);
            });
        } else {
          alert("something went wrong");
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const userloggedinsendData = () => {
    setIsLoading(true);
    const form = new FormData();
    form.append("firstName", adsdata.adsData.firstName);
    form.append("lastName", adsdata.adsData.lastName);
    form.append("phone", adsdata.adsData.phone);
    form.append("email", adsdata.adsData.email);
    form.append("package", adsdata.adsData.package);
    form.append("adsPackageId", adsdata.adsData.adsPackageId);
    form.append("duration", adsdata.adsData.duration);
    form.append("role", adsdata.adsData.role);
    form.append("title", adsdata.adsData.title);
    form.append("username", adsdata.adsData.username);
    form.append("extractobj", JSON.stringify(adsdata.adsData.extractobj));
    for (let i = 0; i < adsdata.adsData.extractimage.length; i++) {
      form.append("adsimage", adsdata.adsData.extractimage[i]);
    }
    // Object.entries(adsdata.adsData.extractobj).forEach(([key, value]) => {
    //   form.append(key, value)
    // })

    for (let key in form) {
      console.log("Keyyyy", key);
    }
    axios
      .post(`${config.apiUrl}/api/ads/addingads`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log("======>", res.data);
        setIsLoading(false);
        alert(res.data.msg);
        nav("/success");
        setTimeout(() => {
          localStorage.removeItem("data");
        }, 4000);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("Error", err);
      });
  };

  if (togglerpay) {
    return (
      <>
        {isLoading ? (
          <div className=" container text-center m-2">
            <Box justifyContent={"center"} alignItems="center">
              <CircularProgress />
            </Box>
          </div>
        ) : (
          <div className="payment-section py-5">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 col-sm-10 col-12">
                  <div className="payment-box">
                    <div className="mb-4 card-box">
                      <img alt="card-img" src="../images/card-img.png" />
                      <span>
                        {
                          <span>
                            {allData.firstName} {allData.lastName}
                          </span>
                        }
                      </span>
                    </div>
                    <PaymentForm
                      applicationId="sandbox-sq0idb-2mvSDK82lt1Q-BPD-wz-sw"
                      cardTokenizeResponseReceived={async (token, buyer) => {
                        console.info({ token, buyer });
                        console.log("tojen", token.token);
                        const response = await axios.post(
                          `${config.apiUrl}/api/payment/pay`,
                          { sourceId: token.token, amt: price },
                          { headers: { "Content-Type": "application/json" } }
                        );
                        console.log("===>", response.data);

                        // setLoader(true);
                        if (response.data.data.status === "COMPLETED") {
                          sendData();
                        }
                      }}
                      //   Billing address
                      createVerificationDetails={() => ({
                        amount: "1000",
                        billingContact: {
                          addressLines: ["123 Main Street", "Apartment 1"],
                          familyName: "Doe",
                          givenName: "rohan",
                          countryCode: "GB",
                          city: "London",
                        },
                        currencyCode: "USD",
                        intent: "CHARGE",
                      })}
                      locationId="LAXJ797WKTD55" //Will get from dashboard
                    >
                      <CreditCard />
                      {/* <p></p>
									<br/>
									<Ach accountHolderName='Rohan'/> */}
                    </PaymentForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        {isLoading ? (
          <div className=" container text-center m-2">
            <Box justifyContent={"center"} alignItems="center">
              <CircularProgress />
            </Box>
          </div>
        ) : (
          <div className="payment-section py-5">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 col-sm-10 col-12">
                  <div className="payment-box">
                    <div className="mb-4 card-box">
                      <img alt="card-img" src="../images/card-img.png" />
                      <span>
                        {
                          <span>
                            {allData.firstName} {allData.lastName}
                          </span>
                        }
                      </span>
                    </div>
                    <PaymentForm
                      applicationId="sandbox-sq0idb-2mvSDK82lt1Q-BPD-wz-sw"
                      cardTokenizeResponseReceived={async (token, buyer) => {
                        console.info({ token, buyer });
                        console.log("tojen", token.token);
                        const response = await axios.post(
                          `${config.apiUrl}/api/payment/pay`,
                          { sourceId: token.token, amt: price },
                          { headers: { "Content-Type": "application/json" } }
                        );
                        console.log("===>", response.data);

                        // setLoader(true);
                        if (response.data.data.status === "COMPLETED") {
                          userloggedinsendData();
                        }
                      }}
                      //   Billing address
                      createVerificationDetails={() => ({
                        amount: "1000",
                        billingContact: {
                          addressLines: ["123 Main Street", "Apartment 1"],
                          familyName: "Doe",
                          givenName: "rohan",
                          countryCode: "GB",
                          city: "London",
                        },
                        currencyCode: "USD",
                        intent: "CHARGE",
                      })}
                      locationId="LAXJ797WKTD55" //Will get from dashboard
                    >
                      <CreditCard />
                      {/* <p></p>
                    <br/>
                    <Ach accountHolderName='Rohan'/> */}
                    </PaymentForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
