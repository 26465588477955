import VideoTile from "./VideoTile";
// import HlsView from "./HlsView";
import {
  selectLocalPeer,
  selectPeers,
  useHMSStore,
} from "@100mslive/react-sdk";
// import { useMedia } from "react-use";
import { useWindowSize } from "react-use";

function Stream() {
  const { width, height } = useWindowSize();
  // const isMobile = useMedia("(max-width: 991px)");
  const peers = useHMSStore(selectPeers);
  const localPeer = useHMSStore(selectLocalPeer);

  const broadcaster = peers.filter((peer) => peer.roleName === "broadcaster");

  // const aspectratio = Math.min(width, height) / Math.max(width, height);
  const horizontal = broadcaster.length ? Math.ceil(Math.sqrt(broadcaster.length)) : 1;
  const vertical = broadcaster.length ? Math.round(Math.sqrt(broadcaster.length)) : 1;

  const box = {};
  if (broadcaster.length) {
    if (width > height) {
      box.maxWidth = String(100 / horizontal) + "%";
      box.maxHeight = String(100 / vertical) + "%";
    } else {
      box.maxWidth = String(100 / horizontal) + "%";
      box.maxHeight = String(100 / vertical) + "%";
    }
  }
  // if (broadcaster.length) {
  //   if (width > height) {
  //     if (aspectratio > 0.501) {
  //       console.log("a");
  //     } else {
  //       console.log("b");
  //       if (broadcaster.length === 2) {
  //         // box.width = "100%";
  //       }
  //     }
  //   } else {
  //     if (aspectratio > 0.501) {
  //       console.log("c");
  //       if (broadcaster.length === 2) {
  //         // box.width = "100%";
  //       } else {
  //       }
  //     } else {
  //       console.log("d");
  //       if (broadcaster.length === 2) {
  //         // box.width = "100%";
  //       }
  //     }
  //   }
  // }
  // console.table(width, height);
  // console.table(broadcaster.length, horizontal, vertical);

  return (
    <div className="stream">
      <div className="liveVdoWrap">
        {localPeer && localPeer?.roleName === "broadcaster" && peers
            .filter((peer) => peer?.roleName === "broadcaster")
            .map((peer, i) => (
              <div
                key={i}
                className={broadcaster.length >= 2 ? "box" : "box"}
                style={box}
              >
                <VideoTile key={peer.id} peer={peer} peers={broadcaster} />
              </div>
            ))}
        {/* {localPeer.roleName === "hls-viewer" && <HlsView />} */}
      </div>
    </div>
  );
}

export default Stream;
