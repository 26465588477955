import React from "react";

const TopicArticle = ({ topic }) => {
  return (
    <>
      <div className="row align-items-center shadow p-4">
        <div className="col-md-3">
          <img src="/images/t1.png" alt="" />
        </div>
        <div className="col-md-9">
          <h2>{topic.title}</h2>
          <hr />
          <p>{topic.description} </p>
        </div>
      </div>
    </>
  );
};

export default TopicArticle;
