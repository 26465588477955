import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import config from '../../../config'

const TopAd = ({ src ,liveStreams}) => {


  return (<>
    { src && ! src[0]?.flagH ? <div className="item">
    <div className="hederAdd p-0 m-0">
    {src && liveStreams.length>0  ? <Link target="_blank" to={src.linkH}> <img src={`${config.apiUrl}/${src?.imageH}`} alt="" className="rightImg" />  </Link> : null}

    </div>
  </div>:null}
     
    </>
  )
}

export default TopAd
