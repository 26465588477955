import React from 'react'
import axios from 'axios';
import { useQuery } from "react-query";
import config from "../../../config";

const AboutTurnLeftSection = () => {

    const { data: about } = useQuery(
        ['About'],
        async () => {
            const url = `${config.apiUrl}/api/about`;
            const { data } = await axios.get(url);
            return data.data[0];
        },
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        }
    );
    return (
        <>
            <section className="turnLeft">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-4">
                            <img src={config.apiUrl + "/" + about?.imageUrl} alt={about?.imageUrl} />
                        </div>
                        <div className="col-md-8">
                            <h2>{about?.title}</h2>
                            {/* <h6>Intelligent-Bold-Conversation</h6> */}
                            <p>{about?.description}.</p>

                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutTurnLeftSection