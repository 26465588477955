import config from "../config";

export const Auth = {
    login,
    registration
};



function login(reqData) {

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(reqData),
    };

    return fetch(`${config.apiUrl}/api/user/login`, requestOptions)
        .then(handleResponse)
        .then((data) => {
            return data;
        });
}

function registration(reqData) {

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(reqData),
    };

    // users/viewer_registration
    return fetch(`${config.apiUrl}/api/user/signup`, requestOptions)
        .then(handleResponse)
        .then((data) => {
            return data;
        });
}


function handleResponse(response) {
    return response.text().then((text) => {
        // console.log(text)
        // // console.log(JSON.parse(text))
        const data = text && JSON.parse(text);
        // console.log(data)
        const error = (data && data.message) || response.message;
        if (error == 'Auth failed') {
            return Promise.reject(error);
        } else {
            if (data && data.error === 1) {
                return Promise.reject(error);
            }
        }
        return data;
    });
}