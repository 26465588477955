import React from "react";
import { useEffect } from "react";
import { useHMSActions } from "@100mslive/react-sdk";
import { selectIsConnectedToRoom } from "@100mslive/react-sdk";
import { useHMSStore } from "@100mslive/react-sdk";
import { AuthState } from "../../context/auth";
import config from "../../config";

const AutoJoin = ({ liveStreams }) => {
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const hmsActions = useHMSActions();

  const { myProfile } = AuthState();

  const handleJoin = async (e) => {
    const roomId = liveStreams?.length ? liveStreams[0].room_id : null;

    const response = await fetch(config.hmsApiTokenUrl, {
      method: "POST",
      body: JSON.stringify({
        room_id: roomId,
        user_id: `${Date.now()}`,
        role: "hls-viewer", //broadcaster, hls-viewer
        type: "app",
      }),
    });
    const { token } = await response.json();

    // Joining the room
    hmsActions.join({
      userName: myProfile?.username,
      authToken: token,
      settings: {
        isAudioMuted: true,
        isVideoMuted: true,
      },
    });
  };

  useEffect(() => {
    if(!isConnected) {
      handleJoin();
    }
  }, []);

  return <div>Connecting...</div>;
};

export default AutoJoin;
