import React from 'react'
import { Link } from 'react-router-dom'
import { useQueryClient } from 'react-query';

const FooterTopicsSection = () => {
  const queryClient = useQueryClient()
  const topics = queryClient.getQueryData('allTopics')
  return (
    <>
      <div className="col-md">
        <h2>topics</h2>
        <ul className="qkLinnk">
          {topics && topics.filter(t => t.showOnMenu).sort(function (a, b) { return a.positionOnMenu - b.positionOnMenu }).map(t => (
            <li className="nav-item" key={t._id}>
              <Link key={t._id} to={`/topic/${t._id}`}>{t.name.toUpperCase()}</Link>
            </li>
          ))}
          {/* <li>
            <Link to="/sports">Sports</Link>
          </li>
          <li>
            <Link to="/politics">Politics</Link>
          </li>
          <li>
            <Link to="/religion">Religion</Link>
          </li>
          <li>
            <Link to="/">Everyday People Issues</Link>
          </li>
          <li>
            <Link to="/">Scocial Media</Link>
          </li>
          <li>
            <Link to="/">All</Link>
          </li> */}
        </ul>
      </div>
    </>
  )
}

export default FooterTopicsSection