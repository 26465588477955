import React, { memo } from 'react'
import axios from 'axios';
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import config from "../../config";

const DebateHistoy = () => {

    const { data: uservideos, isLoading } = useQuery(
        ['uservideos'],
        async () => {
            const headers = {
                headers: {
                    authorization: `Bearer ${localStorage.getItem(
                        "turn2speak_auth_token"
                    )}`,
                },
            };
            const url = `${config.apiUrl}/api/user/videos`;
            const { data } = await axios.get(url, headers);
            return data;
        },
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        }
    );
    const dDate = (debate) => {
        // const startTime = new Date(debate?.startDate).getHours ()
        const endTime =
            new Date(debate?.startDate).getTime() + debate?.duration * 60000;
        const endDate = new Date(endTime);

        var shours = new Date(debate?.startDate).getHours();
        var sminutes = new Date(debate?.startDate).getMinutes();
        var ehours = new Date(endDate).getHours();
        var eminutes = new Date(endDate).getMinutes();

        // Check whether AM or PM
        var snewformat = shours >= 12 ? "PM" : "AM";
        var enewformat = ehours >= 12 ? "PM" : "AM";

        // Find current hour in AM-PM Format
        shours = shours % 12;
        ehours = ehours % 12;

        // To display "0" as "12"
        shours = shours ? shours : 12;
        sminutes = sminutes < 10 ? "0" + sminutes : sminutes;
        ehours = ehours ? ehours : 12;
        eminutes = eminutes < 10 ? "0" + eminutes : eminutes;

        const startTime = shours + ":" + sminutes + " " + snewformat;
        const endT = ehours + ":" + eminutes + " " + enewformat;

        return { startTime, endT, endTime };
    };

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    console.log(uservideos);
    console.log(isLoading);

    return (
        <>
            <div className="histoyDebate">
                <h2>History of debates</h2>

                <div className="debateSlider">
                    <div className="row">
                        {!isLoading && uservideos.map((row, i) => {
                            const poster = row.debate?.imageUrl
                                ? config.apiUrl + "/" + row.debate.imageUrl
                                : "";
                            return (

                                <div key={i} className="col-md-4 col-sm-6 col-12">
                                    <div className="dbateSldr">
                                        <div className="imgSec">
                                            <Link
                                                to={`/watch/${row._id}`}
                                                className={"nav-link"}
                                                aria-current="page"
                                            >
                                                <span>
                                                    {new Date(row.debate?.startDate).getDate()},{" "}
                                                    {months[new Date(row.debate?.startDate).getMonth()]},{" "}
                                                    {new Date(row.debate?.startDate).getFullYear()} / {dDate(row.debate).startTime}
                                                </span>
                                                <img src={poster} alt="poster" />
                                            </Link>
                                        </div>

                                        <div className="txtBx ">
                                            <Link
                                                to={`/watch/${row._id}`}
                                                className={"nav-link"}
                                                aria-current="page"
                                            >
                                                <p>{row.debate?.description} </p>
                                                <h5>{row.debate?.title} </h5>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default memo(DebateHistoy)