import { createContext, useState } from "react";

const MyAdsContext = createContext();

const CustomProvider = ({ children }) => {
    const [adsData, setAdsData] = useState([]);
    return (
        <MyAdsContext.Provider value={{ adsData, setAdsData }}>
            {children}
        </MyAdsContext.Provider>
    );
};

export default CustomProvider;
export { MyAdsContext };
