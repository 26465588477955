// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add your custom styles */
.plyr__controls .plyr__controls__item.plyr__play-large {
    color: #ff0000;
    /* Change to your desired color */
}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
    background-color: #1a49a3;
}

.plyr__volume input[type="range"] {
    color: #1a49a3 !important;
}

.plyr__progress input[type="range"] {
    color: #1a49a3 !important;
}

.plyr__controls .plyr__controls__item:first-child:hover {
    transition: 0.5s linear;
    background-color: #1a49a3;
}

.plyr__control:hover {
    transition: 0.5s linear;
    background-color: #1a49a3 !important;
}

.plyr__controls__item plyr__control {
    display: none !important;
}

/* Player style */

#custom-message {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 20px;
    border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/HLSPlayer/Hls.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;IACI,cAAc;IACd,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,uBAAuB;IACvB,yBAAyB;AAC7B;;AAEA;IACI,uBAAuB;IACvB,oCAAoC;AACxC;;AAEA;IACI,wBAAwB;AAC5B;;AAEA,iBAAiB;;AAEjB;IACI,aAAa;IACb,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,oCAAoC;IACpC,YAAY;IACZ,aAAa;IACb,kBAAkB;AACtB","sourcesContent":["/* Add your custom styles */\n.plyr__controls .plyr__controls__item.plyr__play-large {\n    color: #ff0000;\n    /* Change to your desired color */\n}\n\n.plyr--full-ui.plyr--video .plyr__control--overlaid {\n    background-color: #1a49a3;\n}\n\n.plyr__volume input[type=\"range\"] {\n    color: #1a49a3 !important;\n}\n\n.plyr__progress input[type=\"range\"] {\n    color: #1a49a3 !important;\n}\n\n.plyr__controls .plyr__controls__item:first-child:hover {\n    transition: 0.5s linear;\n    background-color: #1a49a3;\n}\n\n.plyr__control:hover {\n    transition: 0.5s linear;\n    background-color: #1a49a3 !important;\n}\n\n.plyr__controls__item plyr__control {\n    display: none !important;\n}\n\n/* Player style */\n\n#custom-message {\n    display: none;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background-color: rgba(0, 0, 0, 0.7);\n    color: white;\n    padding: 20px;\n    border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
