import React from "react";
import axios from "axios";
import { useQuery } from "react-query";
import TopAd from "./Ad/TopAd";
import RightAd from "./Ad/RightAd";
import AuthView from "./AuthView";
import PublicView from "./PublicView";

import { AuthState } from "../../context/auth";
import config from "../../config";

const Live = () => {
  const { isSignedIn } = AuthState();

  const { data: liveStreams, isLoading } = useQuery(
    ["livestreams"],
    async () => {
      const url = `${config.apiUrl}/api/live-streams`;
      const { data } = await axios.get(url);
      return data.data.data;
    },
    {
      // enabled: hasLiveStreams,
      refetchInterval: 5000,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
    }
  );

  const { data: ads } = useQuery(
    ["getadsonlive"],
    async () => {
      const adsurl = `${config.apiUrl}/api/ads/getadsonlive`;
      const { data } = await axios.get(adsurl);
      return data;
    },
    {
      enabled: !isLoading && liveStreams.length > 0,
      refetchInterval: 5000,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
    }
  );

  console.log("liveStreams", liveStreams);

  return (
    <div className="container-fluid bg-dark p-0 m-0">
      <div className="row p-0 m-0">
        <div className="col p-0 m-0 topads-box-prnt text-center">
          {!isLoading && liveStreams?.length > 0 && ads && !ads[0]?.flagH ? (
            <TopAd src={ads && ads[0]} liveStreams={liveStreams} />
          ) : (
            <p className="text-danger">No Ads Found</p>
          )}
        </div>
      </div>
      <div className="row p-0 m-0">
        <div className="col-11 p-0 m-0 left-ads-box-prnt">
          {!isLoading && liveStreams?.length > 0 ? (
            isSignedIn ? (
              <AuthView liveStreams={liveStreams} />
            ) : (
              <PublicView liveStreams={liveStreams} />
            )
          ) : (
            <img
              width="100%"
              src="images/livestreamisnot.png"
              alt="live stream is not available"
            ></img>
          )}
        </div>
        <div className="col-1 p-0 m-0 right-ads-box-prnt">
          <div className="right-ads-box text-center align-middle">
            {!isLoading && liveStreams?.length > 0 && ads && !ads[1]?.flagV ? (
              <RightAd src={ads && ads[1]} liveStreams={liveStreams} />
            ) : (
              <p className="text-danger">No Ads Found</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Live;
