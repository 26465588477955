import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useMutation, useQuery, useQueryClient } from "react-query";
import config from "../../config";
import axios from "axios";
import { Box } from "@mui/system";
import { Badge } from "@mui/material";
// import NotificationSound from '../../notification-sound.mp3';

// const audioObj = new Audio(NotificationSound);

function Notifications({ handleNav }) {
    const queryClient = useQueryClient();
    const [open, setOpen] = React.useState(false);
    const [playing, setPlaying] = React.useState(false);
    // const [audio] = React.useState(audioObj);

    const handleClickOpen = () => {
        setOpen(true);
        handleNav();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { data } = useQuery(
        ["notifications"],
        async () => {
            const url = `${config.apiUrl}/api/user/getNotifications`;
            const headers = {
                headers: {
                    authorization: `Bearer ${localStorage.getItem(
                        "turn2speak_auth_token"
                    )}`,
                },
            };
            const { data } = await axios.get(url, headers);
            return data;
        },
        {
            refetchInterval: 5000,
            enabled: !!localStorage.getItem("turn2speak_auth_token"),
            // refetchOnWindowFocus: false,
            // refetchOnReconnect: false,
        }
    );

    const { mutate } = useMutation(
        async (v) => {
            const url = `${config.apiUrl}/api/user/deleteNotifications`;
            const headers = {
                headers: {
                    authorization: `Bearer ${localStorage.getItem(
                        "turn2speak_auth_token"
                    )}`,
                },
            };
            const data = {
                notiIds: v,
            };
            await axios.post(url, data, headers);
        },
        {
            onSuccess: () => {
                // alert('Your request has been sent')
                queryClient.refetchQueries(["notifications"]);
                window.location.reload()
            },
        }
    );

    // React.useEffect(() => {
    //     console.log("playing", playing);
    //     if (playing) {
    //         audio.play();
    //         setPlaying(false);
    //     }
    // }, [audio, playing]);

    // React.useEffect(() => {
    //     console.log("totalUnRead", data?.notifications?.length);
    //     if (data?.notifications?.length) {
    //         setPlaying(true);
    //     }
    // }, [data]);

    // console.log("---not-data---", data);
    return (
        <>
            {data?.notifications?.length ? (
                <li className="nav-item" onClick={handleClickOpen}>
                    <div className="nav-link">
                        <Badge badgeContent={data?.notifications?.length} color="primary">
                            <NotificationsIcon />
                        </Badge>
                    </div>
                </li>
            ) : (
                <li className="nav-item" onClick={handleNav}>
                    <div className="nav-link">
                        <Badge color="primary">
                            <NotificationsIcon />
                        </Badge>
                    </div>
                </li>
            )}
            {data?.notifications?.length ? (
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent style={{ maxHeight: "80vh" }}>
                        <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
                            Notifications
                        </DialogTitle>
                        {data?.notifications.map((n) => (
                            <Box
                                key={n._id}
                                sx={{
                                    gridGap: "15px",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <DialogContentText
                                    id="alert-dialog-description"
                                    sx={{
                                        fontWeight: 600,
                                        flex: 2,
                                        ":hover": { cursor: "pointer" },
                                    }}
                                    onClick={() =>
                                        window.location.assign(`/debates/${n.debateId}`)
                                    }
                                >
                                    {n.message}
                                </DialogContentText>
                                <DialogContentText id="alert-dialog-description">
                                    {new Date(n.createdAt).toLocaleString()}
                                </DialogContentText>
                                <Button onClick={() => mutate([n._id])}>Delete</Button>
                            </Box>
                        ))}
                    </DialogContent>
                </Dialog>
            ) : null}
        </>
    );
}

export default Notifications;
