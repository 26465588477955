import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import config from '../../config'
import { NavLink } from 'react-router-dom'

function Debates() {
  const { data: debates, isLoading, isError } = useQuery(
    ['allDebates'],
    async () => {
      const url = `${config.apiUrl}/api/user/debates`
      const { data } = await axios.get(url)
      return data.debates
    },
    {
      // enabled: !!isAuth,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  )

  return (
    <div>
      <section className="bannerSec innerBanner">
        <div
          className="box-position"
          style={{ background: 'url(images/innerBanner.jpg)' }}
        />
        <div className="bannerTxt">
          <h1>Debate</h1>
        </div>
      </section>

      <section className="turnLeft my-5 debateSec">
        <div className="container">
          {debates?.length > 0 &&
            debates?.map((deb, i) => (
              <div key={i}>
                <div key={i} className="row align-items-center shadow p-4">
                  <div className="col-md-3">
                    {deb.imageUrl ? (
                      <img src={`${config.apiUrl}/${deb.imageUrl}`} alt="" />
                    ) : (
                      <img src="images/t8.jpg" alt="" />
                    )}
                  </div>
                  <div className="col-md-9">
                    <h2>{deb.title}</h2>
                    <hr />
                    <p>{deb.description}</p>
                    <NavLink
                      to={`/debates/${deb._id}`}
                      className={'btn1'}
                      aria-current="page"
                    >
                      Participate
                    </NavLink>
                  </div>
                </div>
                <hr />
              </div>
            ))}
        </div>
      </section>
    </div>
  )
}

export default Debates
