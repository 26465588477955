import React from 'react'
import AboutBannerSection from './AboutBannerSection/AboutBannerSection'
import AboutTurnLeftSection from './AboutTurnLeftSection/AboutTurnLeftSection'

const About = () => {
  return (
    <>
        <AboutBannerSection/>
        <AboutTurnLeftSection/>
    </>
  )
}

export default About