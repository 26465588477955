import { memo } from "react";
import config from "../../config";

function Stream({ users }) {
    return (
        <>
            <h6>
                Participate{" "}
                <small>
                    {users?.length > 0 ? "(" + users.filter((peer) => peer.userId).length + " Members)" : null}
                </small>
            </h6>
            <ul className="memberList">
                {users &&
                    users.map((peer, i) => {
                        if (peer.userId) {
                            var src = "/images/l2.jpg";
                            if (peer.userId.pic) {
                                src = `${config.apiUrl}/${peer.userId.pic}`;
                            }
                            return (
                                <li key={i}>
                                    <img src={src} alt={src} />
                                </li>
                            );
                        } else {
                            return null;
                        }
                    })}
            </ul>
        </>
    );
}

export default memo(Stream);
